@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Bungee&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Permanent+Marker&family=Rubik+Bubbles&display=swap");


.hero-container {
  width: 100%;
  height: 100vh;
  top: 68px;
  gap: 0px;
  color: white;
}

.hero-main {
  position: inherit;
  width: 100%;
  height: 100vh;
  top: 68px;
  gap: 0px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.hero-video {
  position: inherit;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: hidden;
  object-fit: cover; /* Ensures video covers the whole area while maintaining aspect ratio */
  object-position: center; /* Keeps the video content centered */
  z-index: 1;
}

.iframe {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures video covers the whole area while maintaining aspect ratio */
  object-position: center; /* Keeps the video content centered */
  z-index: 1;
}
.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    180deg,
    rgba(6, 6, 6, 0.18) 32.72%,
    rgba(6, 6, 6, 0.57) 98.69%
  );
  pointer-events: none; /* So that the video controls are still clickable */
  z-index: 2;
}

.hero-sub-container {
  position: relative;
  width: 1120px;
  font-family: Montserrat;
  color: white;
  top: -57%;
  text-align: center;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  z-index: 4;

  &-header {
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 62.4px */
    text-transform: uppercase;
  }

  &-sub-header {
    width: 1088px;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 27px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 40.5px */
  }
}

 .safari-only {
  transform: translateY(-300%); /* Safari-specific adjustment */
}

@media (min-width: 1728px) {
  .hero-sub-container {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    z-index: 4;
    width: 1400px;

    &-header {
      font-size: 64px;
      font-weight: 700;
      line-height: 120%;
    }

    &-sub-header {
      width: 1366px;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
    }
  }
}

@media (min-width: 834px) and (max-width: 1279px) {
  .hero-sub-container {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    z-index: 4;
    // width: 100%;
    width: 780px;

    &-header {
      text-align: center;
      color: #fff;
      font-family: Montserrat;
      font-size: 36px;
      font-weight: 700;
      line-height: 120%; /* 62.4px */
    }

    &-sub-header {
      width: 753px;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
    }
  }
}

@media (min-width: 390px) and (max-width: 833px) {
  .hero-sub-container {
    display: inline-flex;
    padding: 0px 16px 32px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    z-index: 4;
    position: absolute;
    transform: translate(-50%, -50%); /* Center the container */
    top: 50%;
    left: 50%;
    width: 85%;
  }
  .hero-sub-container-header {
    color: #fff;
    font-family: Montserrat;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 33.6px */
    text-transform: uppercase;
  }
  .hero-sub-container-sub-header {
    color: #fff;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 22.5px */
    width: 100%;
  }

  .hero-video{
    position: relative;
  }
  .hero-container {
    // position: static;
  }
  .hero-main {
    position: absolute;
    top: 0;
  }

  // .hero-sub-container {
  //   width: 358px;
  //   display: inline-flex;
  //   padding: 0px 16px 32px 16px;
  //   flex-direction: column;
  //   justify-content: center;
  //   align-items: flex-start;
  //   gap: 16px;
  //   z-index: 4;

  //   &-header {
  //     color: #fff;
  //     font-family: GothamTwo;
  //     font-size: 28px;
  //     font-weight: 700;
  //     line-height: 120%; /* 62.4px */
  //   }

  //   &-sub-header {
  //     width: 358px;
  //     font-size: 15px;
  //     font-style: normal;
  //     font-weight: 400;
  //     line-height: 150%; /* 22.5px */
  //   }
  // }
}

@media (max-width: 389px) {
  .hero-sub-container {
    width: 358px;
    display: inline-flex;
    padding: 0px 16px 32px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    z-index: 4;

    &-header {
      color: #fff;
      font-family: Montserrat;
      font-size: 28px;
      font-weight: 700;
      line-height: 120%; /* 62.4px */
    }

    &-sub-header {
      width: 358px;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 22.5px */
    }
  }
}
