@import url("https://fonts.googleapis.com/css2?family=BIZ+UDPGothic&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Bungee&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Permanent+Marker&family=Rubik+Bubbles&display=swap");

.impactImgContainer {
  display: flex;
  
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  margin: 0;
    padding: 0;
}
.impactTextContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  padding: 24px 16px 80px 16px;

}
.impactText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.txtGivingDesc {
  color: #fff;
  font-family: Inter;
  font-style: normal;
  line-height: 140%; /* 28px */
  letter-spacing: -0.22px;
  text-align: left;
  margin: 0;
  width: 358px;
  font-size: 15px;
  font-weight: 400;

  &-orange {
    color: #f05a22;
  }
}
.learnMore {
  display: flex;
  padding: 4px 4px 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 100px;
  background: #f05a22;
  border: none;
}

.learnMore:hover {
  cursor: pointer;
}

.learnMoretxt {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;

  &-img {
    width: 32px;
    height: 32px;
  }
}
.alumni {
  height: 0;
  width: 0;
  overflow: hidden;
}
.alumImg {
  width: 176px;
  height: 176px;
  flex-shrink: 0;
  border-radius: 2px;
  background: #d9d9d9;
}

@media screen and (min-width: 390px) {
  .impactImgContainer {
    // display: flex;
    // flex-direction: column;
    // align-items: flex-start;
    // // gap: 24px;
    // // padding: 24px 16px 80px 16px;
    margin: 0;
    padding: 0;
  }

  .impactTextContainer {
    padding: 24px 16px 80px 16px;
  }

  .txtGivingDesc {
    width: 358px;
    font-size: 15px;
    font-weight: 400;
  }

  .learnMore {
    display: flex;
    padding: 4px 4px 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }

  .learnMoretxt {
    margin: 0;
    font-size: 14px;

    &-img {
      width: 32px;
      height: 32px;
    }
  }

  .alumni {
    height: 0;
    width: 0;
    overflow: hidden;
  }
}

@media screen and (min-width: 620px) {
  .impactImgContainer {
    // display: flex;
    // flex-direction: column;
    // align-items: flex-start;
    // // gap: 24px;
    // // padding: 24px 16px 80px 16px;
    
  }

  .impactTextContainer {
    padding: 24px 16px 80px 16px;
  }

  .txtGivingDesc {
    width: 500px;
    font-size: 20px;
    font-weight: 400;
  }

  .learnMore {
    display: flex;
    padding: 4px 4px 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }

  .learnMoretxt {
    margin: 0;
    font-size: 14px;

    &-img {
      width: 32px;
      height: 32px;
    }
  }

  .alumni {
    height: 0;
    width: 0;
    overflow: hidden;
  }
}


@media screen and (min-width: 834px) {
  .impactImgContainer {
    display: flex;
    padding: 48px 24px;
    justify-content: space-between;
    align-items: left;
    margin: 0px;
    
  }
  .impactTextContainer {
    padding: 0;
    display: flex;
    justify-content: left;
    align-items: left;
  }

  .txtGivingDesc {
    width: 417px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
  }

  .learnMore {
    display: flex;
    padding: 4px 4px 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }

  .learnMoretxt {
    margin: 0;
    font-size: 16px;

    &-img {
      width: 28px;
      height: 28px;
    }
  }
  .alumni {
    display: flex;
    width: auto;
    height: auto;
    align-items: center;
    gap: 16px;
    overflow: visible;
  }

  .alumImg {
    width: 100px;
    height: 100px;
  }
}

@media screen and (min-width: 1280px) {
  .impactImgContainer {
    display: flex;
    padding: 0px 24px;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    margin-bottom: 64px;
    margin-top: 64px;
  }

  .txtGivingDesc {
    width: 615px;
    font-size: 24px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.264px;
  }

  .learnMore {
    display: flex;
    padding: 4px 4px 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }

  .learnMoretxt {
    margin: 0;
    font-size: 14px;

    &-img {
      width: 32px;
      height: 32px;
    }
  }
  .alumni {
    height: auto;
    width: auto;
    overflow: visible;
  }
  .alumImg {
    width: 176px;
    height: 176px;
  }
  .alumImg{
    width: 176px;
    height: 176px;
  }
}

@media screen and (min-width: 1728px) {
  .impactImgContainer {
    display: flex;
    padding: 64px 100px;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    margin: 0px;
  }

  .txtGivingDesc {
    width: 817px;
    font-family: Inter;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: -0.352px;
  }

  .learnMore {
    display: flex;
    padding: 4px 4px 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }

  .learnMoretxt {
    font-size: 32px;
    margin: 0;

    &-img {
      width: 64px;
      height: 64px;
    }
  }

  .alumImg{
    width: 198px;
    height: 198px;
  }
}
