.diagram {
  margin-right: 100px;
}
.letters-effect-container {
  width: calc(29vw); /* Calculated based on viewport width */
  height: calc(29vw);
}

@media (min-width: 1728px) {
  .letters-effect-container {
    width: 640px;
    height: 640px;
  }
}

@media (min-width: 1600px) and (max-width: 1727px) {
  .letters-effect-container {
    width: 425px;
    height: 425px;
  }
}

@media (min-width: 1550px)  and (max-width: 1599px)  {
  .letters-effect-container {
    width: 420px;
    height: 420px;
  }
}


@media (min-width: 1500px)  and (max-width: 1549px)  {
  .letters-effect-container {
    width: 400px;
    height: 400px;
  }
}


@media (min-width: 1450px)  and (max-width: 1499px)  {
  .letters-effect-container {
    width: 400px;
    height: 400px;
  }
}

@media (min-width: 1400px)  and (max-width: 1449px) {
  .letters-effect-container {
    width:  384px;
    height:  392px;
  }
}

@media (min-width: 1350px) and (max-width: 1399px) { 
  .letters-effect-container {
    width:  390px;
    height:  390px;
  }
}


@media (min-width: 1280px) and (max-width: 1349px) {
  .letters-effect-container {
    width:  380px;
    height:  380px;
  }
}






@media (min-width: 834px) and (max-width: 1279px) {
  .letters-effect-container {
    width: 360px;
    height: 360px;
  }
}



.letters {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #424545;
  font-size: 40px; /* Adjust as needed */
}

.letter {
  font-family: "Montserrat", sans-serif;
  margin: 0 10px; /* Adjust spacing as needed */
}
