@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

@font-face {
  font-family: "Gotham";
  src: url(../../../public/GothamBold.otf) format("opentype");
}

@-webkit-keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.serviceContainer {
  // some styles for the slick were not working (not displaying slides properly so I overrid them. If I have time I will edit this code so it's not using !important
  .slick-prev, .slick-next {
    display: none !important;
  }
  .slick-track
  {
    display: flex !important;
  }
  .slick-slide
  {
    width: 394px !important;
    margin-left: 20px;
  }

  padding-left: 20px;
}

.serviceCard {
  width: 394px; /* Sets a maximum width for the card */  
  height: 252px;
  border-radius: 4px;
  transition: 3s;
  position: relative; /* Set the container to relative positioning */

  background-image: url(../../images/servicesCardBackground.svg);
  background-size: cover; // Change to 'contain' if you want to fit the image without cutting
  background-position: center;
  background-repeat: no-repeat; // Prevent the background from repeating
  // /* Other styles for the card */
  transition: backdrop-filter 0.3s ease; // Transition for the blur effect
  -webkit-transition: background-image 0.59s ease-in-out;
  transition: background-image 0.59s ease-in-out;

  &.active {
    background-image: url(../../images/servicesCardBackgroundActive.png);
    -webkit-transition: background-image 0.59s ease-in-out;
    transition: background-image 0.59s ease-in-out;
  }
}

.serviceIcons {
  width: 64px;
  height: 64px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  transition:
    opacity 0.59s ease-out,
    transform 0.59s ease-out,
    height 0.59s ease-out,
    width 0.59s ease-out,
}

.serviceIcons.not-active {
  margin-top: 32px;
}

.serviceIcons.active {
  opacity: 0; /* Initially hidden */
  height: 0;
  width: 0;
}

.serviceHeader {
  color: #FFF;
  text-align: center;
  font-family: Inter;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  max-height: 64px;
  line-height: 140%;
  transition:
    transform 0.59s ease-out,
    margin-top 0.59s ease-out
}

.serviceHeader.not-active {
  margin: 12px 10px 0 10px;
}

.serviceHeader.active {
  margin: 24px 10px 0 10px;
}

.serviceInfoDescription.not-active {
  display: none; /* Initially hidden */
  opacity: 0; /* Start as invisible */
}

.serviceInfoDescription.active {
  color: #FFF;

  max-width: 358px;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0;
  line-height: 140%; /* 19.6px */

  display: block;
  margin-left: auto;
  margin-right: auto;

  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

.serviceReadButton {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;  
  position: absolute; /* Lock the button within the container */
  bottom: 20px;
  left: 31%;
  gap: 16px;
  padding: 4px 4px 4px 16px;

  background: rgba(255, 255, 255, 0.22);
  font-weight: 500;
  font-size: 16px;
  border-radius: 100px;
  line-height: normal;
  border: none;
  color:white;

  // the Read More/Read Less buttons was highlighting blue when I clicked it sometimes so this is a fix for that
  .noSelect {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .noSelect:focus {
    outline: none !important;
  }
}

.serviceReadButton:hover {
  cursor: pointer;
}

.serviceReadMoreButtonText {
  color: #EAEAEA;
  font-family: Inter;
  margin: 0;
  width: 83px;
}

.serviceCardAdditionalButtonImg {
   // the Read More/Read Less buttons was highlighting blue when I clicked it sometimes so this is a fix for that
   .noSelect {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .noSelect:focus {
    outline: none !important;
  }
}

.serviceCardAdditionalButtonImg.not-active:hover, .serviceCardAdditionalButtonImg.active:hover {
  background-size: cover; // Change to 'contain' if you want to fit the image without cutting
  background-position: center;
  background-repeat: no-repeat; // Prevent the background from repeating
}

.serviceCardButtons {
  display: flex;
  align-items: center;
  gap: 16px;
  background: transparent;
  width: 100%; /* or another suitable value */
  margin-left: 24px;

  .custom-arrow {
    width: 40px;
    height: 40px;
    background: #333;
    color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    
    &:hover {
      background: #555;
    }
    
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.serviceProgressBar {
  height: 4px;
  background: rgba(255, 255, 255, 0.25);
  max-width: 1700px;
  flex-shrink: 0; /* Prevent shrinking */
  flex-grow: 1; /* Allow to grow within constraints */
  margin-right: 75px;
}

.serviceProgressMade {
  border-radius: 100px;
  background: #F05A22;
  height: 4px;
}

// BREAKPOINTS
@media screen and (max-width: 1279px) {
  .serviceContainer {
    // some styles for the slick were not working (not displaying slides properly so I overrid them. If I have time I will edit this code so it's not using !important
    .slick-slide
    {
      width: 252px !important;
      margin-left: 20px;
    }
    margin-top: 32px;
  }
  .serviceCard {
    width: 252px;
  }

  .serviceIcons {
    width: 48px;
    height: 48px;
  }

  .serviceHeader {
    font-size: 17px;
  }

  .serviceHeader.not-active, .serviceHeader.active {
    margin-left: 5px;
    margin-right: 5px;
  }

  .serviceInfoDescription.active {
    font-size: 12px;  
    max-width: 240px;
  }

  .serviceReadButton {
    left: 21%;
    font-size: 15px;
    line-height: normal;
  }

  .serviceCardButtons {
    margin-left: 14px;
  }

  .serviceProgressBar {
    margin-right: 45px;
  }
}

@media screen and (max-width: 833px) {
  .serviceInfoDescription.active {
    max-width: 236px;
  }

  .serviceCardButtons {
    margin-left: 6px;
  }

  .serviceProgressBar {
    margin-right: 25px;
  }
}

@media screen and (min-width: 1728px) {
  .serviceContainer {
    .slick-slide
    {
      width: 494px !important;
    }
    margin-bottom: 48px;
    padding-left: 30px;
  }

  .serviceCard {
    width: 494px;
    height: 316px;
  }

  .serviceIcons {
    width: 80px;
    height: 80px;
  }

  .serviceHeader {
    font-size: 32px;
    max-height: 90px;
  }

  .serviceHeader.not-active, .serviceHeader.active {
    margin-left: 20px;
    margin-right: 20px;
  }

  .serviceReadButton {
    font-size: 24px;
  }

  .serviceReadMoreButtonText {
    width: 124px;
  }

  .serviceInfoDescription.active {  
    max-width: 420px;
    font-size: 18px;
  }

  .serviceCardButtons {
    gap: 32px;

    .custom-arrow {
      width: 62px;
      height: 62px;
      
      svg {
        width: 31px;
        height: 31px;
      }
    }
  }
}