.logo-container {
  margin: 0 48px 48px 24px;
  padding: 0;
  box-sizing: border-box;
  height: 30px;
}

@keyframes slide {
  from {
    transform: translateX(-45%);
  }
  to {
    transform: translateX(45%);
  }
}

.logos {
  overflow: hidden;
  padding: 0;
  background: black;
  white-space: nowrap;
  position: relative;
}

.logos:before,
.logos:after {
  position: absolute;
  top: 0;
  width: 250px;
  height: 100%;
  content: "";
  z-index: 2;
}

.logos:before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), black);
}

.logos:after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), black);
}

.logos-slide {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  animation: 30s slide linear infinite;
  // margin-top: 2%;
  // margin-bottom:2%;
}

.logos-slide img {
  // height: 48px;

  padding: 0 48px;
  // background: lightgray 0px 0.113px / 100% 99.245% no-repeat;

  background: linear-gradient(
    90deg,
    #060606 0%,
    rgba(6, 6, 6, 0) 1.83%,
    rgba(6, 6, 6, 0) 98.14%,
    #060606 100%
  );

  //border-right: 3px solid rgba(255, 255, 255, 0.10);;
}

.border-right {
  font-size: 48px;
  height: 48px;
  width: 2px;
  border-right: 3px solid rgba(255, 255, 255, 0.1);
}

// @media screen and (max-width: 390px) {
//   #Honda-image {
//   }
//   #Honda-image {
//   }
//   #subaru-image {
//   }
//   #tinder-image,
//   #verizon-image,
//   #usc-image {
//   }

//   #Google-image {
//   }

//   #Lakers-image,
//   #snap-image,
//   #dollarshaveclub-image {
//   }
// }
