@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Koulen&display=swap");

.home-cards {

  width: 100%;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  background: url("../../../images/BG_Gradient_IMG.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.home-heading {
  width: 100%;
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
  text-transform: uppercase;
  text-align: left;
  left: 2100px;
  position: absolute;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: transform 0.2s ease;
  margin-bottom: 100px;
}

