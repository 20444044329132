@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Bungee&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Permanent+Marker&family=Rubik+Bubbles&display=swap");

// Styling for backgroung gradient picture
.bggradient{
  width: 100%;
  height: 550px;
  z-index: 0;
}
.WholeComponent {
  z-index: 1;
  padding: 0;

  /* Set the background image */
  background-image: url("../../../images/gradient_image.png");
  background-size: cover;  /* Cover the entire container */
  background-position: center;  /* Center the background image */
  background-repeat: no-repeat;  /* Prevent repeating */
}

.WholeComponent > * {
  margin: 0;
  padding: 0;
  flex: 1; /* Makes children stretch to fill the container */
}
.componentContainer {
  display: flex;
  padding: 32px 32px;
  align-items: flex-start;
  gap: 32px;
  z-index: 2;
}

.parentContainer {
  display: flex;
  gap: 24px;
  width: 100%;
  z-index: 2;
  position: relative;
}

.subContainer11 {
  width: 100%; /* Full viewport width */
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  align-self: stretch;
  box-sizing: border-box; /* Ensure padding doesn't affect the width */
  margin: 0; /* Remove any default margins */
}

.tab {
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  cursor: pointer;
  transition: background 0.3s ease;
  opacity: 0.5;

  /* Apply only top border */
  border-top: solid rgba(255, 255, 255, 0.36);
  border-left: none;
  border-right: none;
  border-bottom: none; /* No bottom border for most tabs */
}

/* Last tab has both top and bottom borders */
.tab:last-child {
  border-bottom: solid rgba(255, 255, 255, 0.36);
}

.tab.active {
  background: rgba(0, 0, 0, 0.18);
  opacity: 1;
}

.tabName{
  width: 160px;
}

.tabPosition.active{
  color: #FFF;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px; /* 124.444% */
}

.tabName.active {
  color: #FFF;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px; /* 124.444% */
}

.tabPosition {
  // text CSS
  color: #FFF;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px; /* 124.444% */

  // positioning CSS
  display: flex;
  width: 250px; /* Fixed width ensures uniform starting positions */
  position: relative;
  text-align: left;
  text-indent: 0px;

  height: auto;
  left: 4%;
}

.tabName {
  color: #FFF;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px; /* 124.444% */

  display: flex;

  // background-color: blue;
}

.specialTabPosition{
  display: flex;
  width: 250px; /* Fixed width ensures uniform starting positions */
  position: relative;
  text-align: left;
  text-indent: 0px;

  height: auto;
  left: 4%;
}


// .specialTabPosition{
//   display: flex;
//   position: relative;
//   text-align: left;
//   text-indent: 10px;
//   // background-color: red;

//   width: 28%;
//   height: auto;
//   left: 5.5%;
// }

.tabImage {
  width: 145px;
  height: 64px;
  position: relative;
}

.specialImageClass {
  width: 145px;
  height: 85px;
  position: relative;
}

.tabImage img {
  width: 45%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.specialImageClass img {
  width: 60%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.subContainer23 {
    display: flex; 
    flex-direction: column; 
    align-items: flex-start; 
    gap: 16px; 
}

.alumniImgContainer {
  width: 466px;
  height: 256px;
  overflow: hidden;
  position: relative;
  margin-bottom: -10px;
}

.miguelImg {
  width: 100%;
  position: absolute;
  top: -120px;
  left: 0px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.malikImg {
  width: 100%;
  position: absolute;
  top: -140px;
  left: 0px;
  filter: grayscale(100%);
}

.sergioImg {
  width: 100%;
  position: absolute;
  top: -100px;
  left: 0px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.victorImg {
  width: 100%;
  position: absolute;
  top: -60px;
  left: 0px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.alumniBioContainer {
  width: 466px;
  color: #808080;
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}

.extendStoriesButton {
  background: none;
  border: none;
}

.expandedStoriesParentContainer {
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 0.5s ease, transform 0.5s ease, visibility 0s ease 0.5s;
  visibility: hidden;
  position: absolute;
  width: 100%;
  height: 210vw;
  z-index: 200;
  background-color: rgb(0, 0, 0);
  text-align: left;
  align-items: center;
}

.expandedStoriesParentContainer.activeExpandStories {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
  transition: opacity 0.5s ease, transform 0.5s ease;
  top: 0;
  left: 0;
}

.expandedStoriesParentContainer.inactiveExpandStories {
  opacity: 0;
  transform: translateY(100%); /* Slide down off screen */
  display: none;
  visibility: hidden;
  transition: opacity 0.5s ease, transform 3s ease, visibility 0s ease 0.5s, display 5s ease;
}

#readMoreButton {
  //Button Format
  display: flex;
  padding: 3px 3px 3px 12px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  height: 24%;
  //Button Style
  border-radius: 100px;
  background: rgba(255, 255, 255, 0.22);

  cursor: pointer;
}

.readMoreButtonContent {
  display: inline-block;
  color: #FFF;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

#readMoreButtonIcon {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
}

.bioPeak {
  height: 65px;
  align-self: stretch;

  overflow: hidden;
  color: #FFF;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limits text to 2 lines */
  -webkit-box-orient: vertical;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  white-space: normal; /* Allows text to wrap */
}

.Header{
  align-self: stretch;
  text-align: left;
  color: #FFF;
  font-family: Gotham;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  padding-top: 58px;
  padding-left: 32px;
  text-transform: uppercase;
}

.Subheader {
  text-align: left;
  padding-left: 32px;
  color: #FFF;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}


// ----------------------FOR THE EXPANDED STORIES POP UP---------------------- //
/* impactAlumniImpact.scss */

/* New style for blurring the background */
.blurBackground .componentContainer,
.blurBackground .Header,
.blurBackground .Subheader {
  filter: blur(5px); /* Adjust the blur intensity as needed */
  transition: filter 0.3s ease; /* Smooth transition for the blur effect */
}

/* Existing styles */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95); /* Optional: Add a slight scaling effect */
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Darkened background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it’s on top */
  opacity: 0;
  animation: fadeInOverlay 0.5s forwards; /* Fade-in animation for the overlay */
}

@keyframes fadeInOverlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.popup {
  // background: #1A1A1A;
  padding: 32px; /* Use padding for consistent space inside the modal */
  border-radius: 16px;
  position: relative;
  max-width: calc(100% - 76px); /* Use percentages to better handle smaller screens */
  max-height: calc(100% - 222px); /* Adjust to ensure it doesn't overflow the viewport */
  opacity: 0;
  animation: fadeIn 0.5s forwards;
  z-index: 1000;
  margin: 11px 38px; /* Apply margins consistently on all sides */
  box-sizing: border-box; /* Ensure padding is included in the width and height calculations */
}

@media (max-width: 600px) {
  .popup {
    margin: 20px; /* Reduce the margin on small screens */
    padding: 16px; /* Adjust padding on small screens */
    max-width: calc(100% - 40px); /* Ensure the modal still fits within the viewport */
    max-height: calc(100% - 40px); /* Ensure the modal still fits within the viewport */
  }
}


#cancelButtonMain {
  position: absolute !important;
  top: 60px !important; /* Adjust the position so it's always outside the popup */
  right: 20px !important; /* Adjust the position so it's always outside the popup */
  background: none !important;
  border: none !important;
  cursor: pointer !important;
  z-index: 1001; /* Ensure it’s on top of the popup */
}

#cancelButtonMain img {
  width: 40% !important;
  height: 40% !important;
}



// ----------------------FOR RESPONSIVENESS---------------------- //
/* Responsive styling for 1280px by 832px */
@media screen and (max-width: 1280px) {
  .specialTabPosition{
    display: flex;
  }

  .tab.active,
  .tab{
    gap: 2vw;
    box-sizing: border-box; /* Include padding in the total width of the tab */
  }

  .sergioImg,
  .miguelImg,
  .malikImg,
  .victorImg {
    width: 100%;   /* Image fills container width */
    height: auto;  /* Image fills container height */
    object-fit: contain; /* Maintains aspect ratio */
  }

  .alumniImgContainer {
    max-width: 466px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .alumniBioContainer {
    max-width: 466px;
  }
}

@media screen and (max-width: 1155px) {
  .tabName, .tabName.active, .tabPosition, .tabPosition.active {
    font-size: max(1.6vw, 13.344px);
  }

  .sergioImg,
  .miguelImg,
  .malikImg {
    top: -50px;
  } 

  .victorImg {
    top: -30px;
  } 

  .alumniImgContainer {
    max-width: 328px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .alumniBioContainer {
    max-width: 328px;
  }
}

@media screen and (max-width: 1054px) and (min-width: 835px) {
  .subContainer11 {
    min-width: 30vw;
    // max-width: 55vw;
  }

  .tabName{
    max-width: 21vw;
    // background-color: red;
    white-space: normal; /* Allow text to wrap to multiple lines */
    word-wrap: break-word; /* Break long words if necessary */
  }
}

@media screen and (max-width: 863px) and (min-width: 835px) {
  .tabName{
    width: 21vw;
  }
}


/* Responsive styling for 834px by 1194px */
@media screen and (max-width: 834px) {
  .Header{
    font-size: 32px;
  }

  .Subheader{
    font-size: 16px;
  }

  .sergioImg,
  .miguelImg,
  .malikImg,
  .victorImg {
    // width: 100%;        /* Makes the image width responsive */
    // height: auto;       /* Maintains aspect ratio */
    // max-width: 328px;   /* Maximum width limit */
    // max-height: 328px;  /* Maximum height limit */
    // flex-shrink: 1;     /* Allow the images to shrink */

    width: 100%;   /* Image fills container width */
    height: auto;  /* Image fills container height */
    object-fit: contain; /* Maintains aspect ratio */
  }

  .bioPeak {
    width: 40vw; /* Make text block scale with viewport width */
    height: auto; /* Adjust height automatically based on content */
    overflow: hidden; /* Hide overflowing text */
    text-overflow: ellipsis; /* Add ellipsis for overflowed text */
    display: -webkit-box; /* Flexbox behavior */
    -webkit-line-clamp: 5; /* Limit text to 5 lines */
    -webkit-box-orient: vertical; /* Vertical orientation */
    white-space: normal; /* Allow text to wrap */
    font-size: 14px;
  }
  
  .subContainer23{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1vw;
    align-self: stretch;
    width: 40vw; /* Scale based on viewport width */
    height: 350.29px;
  }

  .Header, .Subheader {
    padding-left: 24px;
    padding-right: 24px;
  }

  .tab.active,
  .tab{
    max-width: 50vw;
  }

  .tabName,
  .tabPosition,
  .tabName.active,
  .tabPosition.active
  {
    color: #FFF;
    font-family: Inter;
    font-size: 1.6vw;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
  }

  .tab{
    border-top: 0.5px solid rgba(255, 255, 255, 0.36);
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.36);
    opacity: 0.7;
    height: 80px;

    // flex: 1 0 auto; /* Make the tabs flexible and prevent shrinking */
    // min-width: 40vw; /* Ensure the tabs have a minimum width that adjusts based on viewport */
    // max-width: 100%; /* Prevent the tabs from growing too large */
    // align-content: flex-start;
  }

  .tabPosition{
    left: 0.5%;
    width: 100%;
    // background-color: green;

    white-space: normal; /* Allow text to wrap to multiple lines */
    word-wrap: break-word; /* Break long words if necessary */
  }

  .specialTabPosition{
    width: 100%;
    left: 0.5%;
    // background-color: red;
  }

  .tabImage img {
    width: 60%;
  }

  .specialImageClass img{
    width: 80%;
    left: 50%;
    // background-color: blue;
  }

  .tabName{
    width: 30vw;
    // background-color: red;
    white-space: normal; /* Allow text to wrap to multiple lines */
    word-wrap: break-word; /* Break long words if necessary */
  }

  .subContainer11{
    width: 53vw;
  }

  .parentContainer{
    gap: 16px;
  }

  .componentContainer{
    padding: 24px 24px 48px 24px;
  }

  .alumniImgContainer {
    max-width: 328px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .alumniBioContainer {
    max-width: 368px;
  }
}

@media screen and (max-width: 662px) and (min-width: 431px) {
  .tabName{
    width: 40vw;
  }
}

/* Responsive styling for 390px by 844px */
@media screen and (max-width: 430px) and (orientation: portrait) {
  .Header {
    color: #FFF;
     font-family: Montserrat;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 42px */
    padding-left: 16px;
    text-transform: uppercase;
  }

  .Subheader {
    color: #FFF;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 22.5px */
    max-width: 358px;
    padding: 0px 16px 0px 16px;
  }

  .parentContainer {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    gap: 16px; /* Add space between the stacked containers */
  }

  .subContainer23, 
  .subContainer11 {
    display: flex;
    width: auto; /* Ensure full width for both containers when stacked */
  }

  .alumniImgContainer {
    width: 100%;
  }

  .sergioImg,
  .miguelImg,
  .malikImg,
  .victorImg {
    width: 100%;
    height: auto;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }

  .alumniBioContainer {
    width: 100%;
  }

  .bioPeak {
    width: 100%;
  }

  #readMoreButton{
    height: 38px;
  }

  /* Adjust tab padding and make sure it obeys parent container */
  .tab {
    padding: 8px 12px;        /* Adjust padding for smaller screens */
    border-top: 0.5px solid rgba(255, 255, 255, 0.36);
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.36);
    opacity: 0.7;
    max-width: 100%; /* Make sure tabs take up the full width */
    box-sizing: border-box; /* Ensure padding and borders are included in width */
  }

  .tab.active{
    max-width: 100%;
  }

  /* Ensuring tabName and tabPosition are responsive and handle ellipsis */
  .tabName,
  .tabPosition,
  .tabName.active,
  .tabPosition.active {
    font-size: 13px;
    // white-space: nowrap;      /* Prevent text from wrapping to the next line */
    overflow: hidden;         /* Hide any overflowed text */
    text-overflow: ellipsis;  /* Add ellipsis ("...") for any overflowed text */
    width: 100%;              /* Make sure it takes full width */
    min-width: 0;             /* Allow shrinking in flexbox */
    align-content: center;
  }

  .tabName,
  .tabName.active {
    width: 100%;
    white-space: normal; /* Allow text to wrap if needed */
    word-wrap: break-word; /* Break long words if necessary */
    line-height: 1.2; /* Adjust line height for readability */
  }

  .tabPosition,
  .tabPosition.active {
    // display: flex;
    justify-content: space-between;
    overflow: hidden;         /* Hide any overflowed text */
    text-overflow: ellipsis;  /* Add ellipsis ("...") for any overflowed text */
    width: 100%;              /* Make sure it takes full width */
    min-width: 0;             /* Allow shrinking in flexbox */
    left: 3px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    // background-color: blue;   /* For debugging */
  }

  .specialTabPosition {
    text-align: left;
    text-indent: 0px;
  }

  .backendEngineerPosition {
    margin-bottom: 12px;
  }

  .specialImageClass img {
    width: 70%;
  }

  .tabImage{
    width: 80%;
  }

  .componentContainer {
    padding: 16px 16px 32px 16px; /* Reduced padding for smaller screens */
  }
}
