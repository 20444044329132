@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Bungee&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Permanent+Marker&family=Rubik+Bubbles&display=swap");


.top_diamond_container {
  position: relative;
  margin-bottom: 15%;
  width: 100vw;
}

$circle-size: 54px;
$text-color: #434343;
$highlight-color: #f05a22;
$font-size: 24px;
$font-weight: 800;
$z-index-text: 2;

#process_top_square_outline {
  width: 420px;
  height: auto;
  transform: rotate(45deg);
  margin-top: 180px;
  position: relative; /* Make it a reference point for absolute positioning */
  z-index: 2;
}

.text {
  color: $text-color;
  font-size: $font-size;
  font-weight: $font-weight;
  position: absolute;
  transition: color 2s ease-in-out;
  z-index: $z-index-text;
   font-family: Montserrat;
  text-transform: uppercase;
}

#research_text_right,
#research_text_left,
#planning_text_right,
#planning_text_left {
  @extend .text;
}

#research_text_right {
  top: 30%; /* Center it vertically */
  right: -25%; /* Center it horizontally */
}

#research_text_left {
  top: 30%; /* Center it vertically */
  left: -25%; /* Center it horizontally */
}

#planning_text_left {
  bottom: 0%; /* Center it vertically */
  left: -25%; /* Center it horizontally */
}
#planning_text_right {
  bottom: 0%; /* Center it vertically */
  right: -25%; /* Center it horizontally */
}

#client_analysis {
  color: #fff;
  text-align: center;
   font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 100% */
  text-transform: uppercase;
  top: 60%;
  position: absolute;
  // left: 18%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}

#top_left_arrow,
#top_right_arrow,
#bottom_left_arrow,
#bottom_right_arrow {
  position: absolute;
  z-index: 0;
}

#top_left_arrow {
  top: 33%;
  // left: 44%;
  transform: rotate(45deg);
}

#top_right_arrow {
  top: 33%;
  // left: -35%;
  transform: rotate(135deg);
}

#bottom_left_arrow {
  top: 90%;
  // left: 44%;
  transform: rotate(135deg);
}

#bottom_right_arrow {
  top: 90%;
  // left: -35%;
  transform: rotate(45deg);
}

#arrows_overlay {
  position: absolute;
  top: -10%;
  left: 0;
  width: 100%;
  height: 130%;
}

#top_letters_container {
  position: absolute;
  bottom: 1%;
  transform: rotate(45deg);
  left: 17%;
  z-index: 3;
}

// Media query for 1728px width and higher
@media (min-width: 1728px) {
  .mainContainer {
    margin-top: 0;
    gap: 0;
    margin-bottom: 0;
  }

  #client_analysis {
    font-size: 58px;
    line-height: 120%; /* 69.6px */
  }

  #client_analysis {
    left: 50%;
    transform: translateX(-50%);
    top: 52%;
  }

  #process_top_square_outline {
    width: 720px;
    // height: auto;
    max-height: auto;
    margin-top: 50px;
  }

  #top_letters_container {
    bottom: 5.5%;
    left: 31.5%;
  }
  #bottom_letters_container {
    bottom: 5.5%;
    left: 31.5%;
  }

  #bottom_left_arrow,
  #bottom_right_arrow,
  #top_left_arrow,
  #top_right_arrow {
    width: 690px;
  }

  #top_left_arrow,
  #bottom_left_arrow {
    // left: 0;
    right: 12%;
  }

  #top_left_arrow,
  #top_right_arrow {
    top: 10%;
  }

  #bottom_left_arrow,
  #bottom_right_arrow {
    top: 86%;
  }

  #top_right_arrow,
  #bottom_right_arrow {
    left: 12%;
  }
  #arrows_overlay {
    top: -19%;
    left: 0;
    width: 100%;
    height: 137%;
  }
  #research_text_right,
  #research_text_left {
    top: 10%; /* Center it vertically */
    // right: 10%; /* Center it horizontally */
  }

  #planning_text_right {
    bottom: 0%; /* Center it vertically */
    // right: 10%; /* Center it horizontally */
  }

  #planning_text_left {
    bottom: 0%; /* Center it vertically */
    // left: 10%; /* Center it horizontally */
  }
  #planning_text_left,
  #planning_text_right {
    width: 432px;
  }

  #research_text_right,
  #planning_text_right {
    right: 7%; /* Center it horizontally */
  }
  #research_text_left,
  #planning_text_left {
    left: 7%; /* Center it horizontally */
  }
  #planning_text_left {
    left: 3%;
  }
  #planning_text_right {
    right: 3%;
  }

  #research_text_right,
  #research_text_left,
  #planning_text_right,
  #planning_text_left {
    font-size: 48px;
    font-weight: 700;
    line-height: 120%;
  }

  .top_diamond_container {
    margin-bottom: 14%;
  }
}

// Media query for 1440px width and 1440px height
@media (min-width: 1440px) and (max-width: 1727px) {
  .mainContainer {
    margin-top: 0;
    gap: 0;
    margin-bottom: 0;
  }

  #client_analysis {
    left: 50%;
    transform: translateX(-50%);
    top: 55%;
  }

  #process_top_square_outline {
    width: 430px;
    // height: auto;
    max-height: auto;
    margin-top: 50px;
  }

  #top_letters_container {
    // display: none; /* Hide the element */
    bottom: 5.5%;
    left: 36.75%;
  }

  #bottom_letters_container {
    bottom: 5.5%;
    left: 36.75%;
  }

  #bottom_left_arrow,
  #bottom_right_arrow,
  #top_left_arrow,
  #top_right_arrow {
    width: 400px;
  }

  #top_left_arrow,
  #bottom_left_arrow {
    // left: 0;
    right: 22%;
  }

  #top_left_arrow,
  #top_right_arrow {
    top: 18%;
  }

  #bottom_left_arrow,
  #bottom_right_arrow {
    top: 86%;
  }

  #top_right_arrow,
  #bottom_right_arrow {
    left: 22%;
  }
  #arrows_overlay {
    top: -10%;
    left: 0;
    width: 100%;
    height: 130%;
  }
  #research_text_right {
    top: 20%; /* Center it vertically */
    // right: 10%; /* Center it horizontally */
  }
  #research_text_left {
    top: 20%; /* Center it vertically */
    // left: 10%; /* Center it horizontally */
  }

  #planning_text_right {
    bottom: 0%; /* Center it vertically */
    // right: 10%; /* Center it horizontally */
  }

  #planning_text_left {
    bottom: 0%; /* Center it vertically */
    // left: 10%; /* Center it horizontally */
  }
  #planning_text_left,
  #planning_text_right {
    width: 216px;
  }

  #research_text_right,
  #planning_text_right {
    right: 20%; /* Center it horizontally */
  }
  #research_text_left,
  #planning_text_left {
    left: 20%; /* Center it horizontally */
  }

  #planning_text_left {
    left: 16%;
  }
  #planning_text_right {
    right: 16%;
  }

  #research_text_right,
  #research_text_left,
  #planning_text_right,
  #planning_text_left {
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
  }

  .top_diamond_container {
    margin-bottom: 10%;
  }
}

// Media query for 390px width and 844px height
@media (min-width: 1280px) and (max-width: 1439px) {
  .mainContainer {
    margin-top: 0;
    gap: 0;
    margin-bottom: 0;
  }

  #client_analysis {
    // left: 39%;
    top: 55%;
  }

  #process_top_square_outline {
    width: 420px;
    // height: auto;
    max-height: auto;
    margin-top: 50px;
  }

  #top_letters_container {
    // display: none; /* Hide the element */
    bottom: 5%;
    transform: rotate(45deg);
    left: 35%;
  }
  #bottom_letters_container {
    left: 35%;
    bottom: 5%;
  }

  #bottom_left_arrow,
  #bottom_right_arrow,
  #top_left_arrow,
  #top_right_arrow {
    width: 400px;
  }

  #top_left_arrow,
  #bottom_left_arrow {
    // left: 0;
    right: 20%;
  }

  #top_left_arrow,
  #top_right_arrow {
    top: 20%;
  }

  #bottom_left_arrow,
  #bottom_right_arrow {
    top: 86%;
  }

  #top_right_arrow,
  #bottom_right_arrow {
    left: 20%;
  }
  #arrows_overlay {
    top: -10%;
    left: 0;
    width: 100%;
    height: 129%;
  }
  #research_text_right {
    top: 20%; /* Center it vertically */
    // right: 10%; /* Center it horizontally */
  }
  #research_text_left {
    top: 20%; /* Center it vertically */
    // left: 10%; /* Center it horizontally */
  }

  #planning_text_right {
    bottom: 0%; /* Center it vertically */
    // right: 10%; /* Center it horizontally */
  }

  #planning_text_left {
    bottom: 0%; /* Center it vertically */
    // left: 10%; /* Center it horizontally */
  }
  #planning_text_left,
  #planning_text_right {
    width: 216px;
  }

  #research_text_right,
  #planning_text_right {
    right: 20%; /* Center it horizontally */
  }
  #research_text_left,
  #planning_text_left {
    left: 20%; /* Center it horizontally */
  }

  #planning_text_left {
    left: 16%;
  }
  #planning_text_right {
    right: 16%;
  }

  #research_text_right,
  #research_text_left,
  #planning_text_right,
  #planning_text_left {
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
  }

  .top_diamond_container {
    margin-bottom: 10%;
  }
}

// // Media query for 390px width and 844px height

@media (min-width: 834px) and (max-width: 1279px) {
  .mainContainer {
    margin-top: 0;
    gap: 0;
    margin-bottom: 0;
  }

  #client_analysis {
    font-size: 28px;
    line-height: 32px; /* 160% */
  }

  // #client_analysis {
  //   left: 35%;
  // }

  #process_top_square_outline {
    width: 60%;
    // height: auto;
    height: auto;
    margin-top: 100px;
  }
  #top_letters_container {
    // display: none; /* Hide the element */
    bottom: 2%;
    transform: rotate(45deg);
    left: 28.5%;
  }
  #bottom_letters_container {
    left: 24%;
    bottom: 5%;
  }

  #bottom_left_arrow,
  #bottom_right_arrow,
  #top_left_arrow,
  #top_right_arrow {
    width: 55%;
  }

  #top_left_arrow,
  #bottom_left_arrow {
    // left: 0;
    right: 0;
  }

  #top_left_arrow,
  #top_right_arrow {
    top: 22%;
  }

  #bottom_left_arrow,
  #bottom_right_arrow {
    top: 89%;
  }

  #top_right_arrow,
  #bottom_right_arrow {
    left: 0;
  }

  #arrows_overlay {
    top: -6%;
    left: 0;
    width: 100%;
    height: 123%;
  }

  #research_text_right {
    top: 25%; /* Center it vertically */
    // right: 10%; /* Center it horizontally */
  }
  #research_text_left {
    top: 25%; /* Center it vertically */
    // left: 10%; /* Center it horizontally */
  }

  #planning_text_right {
    bottom: 0%; /* Center it vertically */
    // right: 10%; /* Center it horizontally */
  }

  #planning_text_left {
    bottom: 0%; /* Center it vertically */
    // left: 10%; /* Center it horizontally */
  }
  #planning_text_left,
  #planning_text_right {
    width: 153px;
  }

  #research_text_right,
  #planning_text_right {
    right: 5%; /* Center it horizontally */
  }
  #research_text_left,
  #planning_text_left {
    left: 5%; /* Center it horizontally */
  }

  #research_text_right,
  #research_text_left,
  #planning_text_right,
  #planning_text_left {
    font-size: 17px;
    font-weight: 700;
    line-height: 24px; /* 141.176% */
  }
}

// Media query for 390px width and 844px height
@media (min-width: 390px) and (max-width: 833px) {
  .mainContainer {
    margin-top: 0;
    gap: 0;
    margin-bottom: 0;
  }

  #client_analysis {
    color: #fff;
    text-align: center;
  font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 160% */
    text-transform: uppercase;
  }

  // #client_analysis {
  //   left: 25%;
  // }

  #process_top_square_outline {
    width: 60%;
    // min-width: 230px;
    margin-top: 90px;
    height: auto;
  }

  #top_letters_container,
  #bottom_letters_container {
    display: none; /* Hide the element */
  }

  #bottom_left_arrow,
  #bottom_right_arrow,
  #top_left_arrow,
  #top_right_arrow {
    width: 51%;
  }

  #top_left_arrow,
  #bottom_left_arrow {
    // left: 0;
    right: 2%;
  }
  #top_left_arrow,
  #top_right_arrow {
    top: 31%;
  }

  #top_right_arrow,
  #bottom_right_arrow {
    left: 2%;
  }
  #arrows_overlay {
    top: 9%;
    left: 0;
    width: 100%;
    height: 104%;
  }
  #research_text_right {
    top: 25%; /* Center it vertically */
    // right: 10%; /* Center it horizontally */
  }
  #research_text_left {
    top: 25%; /* Center it vertically */
    // left: 10%; /* Center it horizontally */
  }

  #planning_text_right {
    bottom: 0%; /* Center it vertically */
    // right: 10%; /* Center it horizontally */
  }

  #planning_text_left {
    bottom: 0%; /* Center it vertically */
    // left: 10%; /* Center it horizontally */
  }
  #planning_text_left,
  #planning_text_right {
    width: 73px;
  }

  #research_text_right,
  #planning_text_right {
    right: 5%; /* Center it horizontally */
  }
  #research_text_left,
  #planning_text_left {
    left: 5%; /* Center it horizontally */
  }

  #research_text_right,
  #research_text_left,
  #planning_text_right,
  #planning_text_left {
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 184.615% */
  }
}

// Media query for 390px width and 844px height
@media (max-width: 389px) {
  .mainContainer {
    margin-top: 0;
    gap: 0;
    margin-bottom: 0;
  }

  #client_analysis {
    color: #fff;
    text-align: center;
  font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 160% */
    text-transform: uppercase;
    width: 190px;
  }

  // #client_analysis {
  //   left: 25%;
  // }

  #process_top_square_outline {
    width: 60%;
    // min-width: 230px;
    margin-top: 90px;
    height: auto;
  }

  #top_letters_container,
  #bottom_letters_container {
    display: none; /* Hide the element */
  }

  #bottom_left_arrow,
  #bottom_right_arrow,
  #top_left_arrow,
  #top_right_arrow {
    width: 51%;
  }

  #top_left_arrow,
  #bottom_left_arrow {
    // left: 0;
    right: 2%;
  }
  #top_left_arrow,
  #top_right_arrow {
    top: 31%;
  }

  #top_right_arrow,
  #bottom_right_arrow {
    left: 2%;
  }

  #arrows_overlay {
    top: 9%;
    left: 0;
    width: 100%;
    height: 104%;
  }

  #research_text_right {
    top: 25%; /* Center it vertically */
    // right: 10%; /* Center it horizontally */
  }
  #research_text_left {
    top: 25%; /* Center it vertically */
    // left: 10%; /* Center it horizontally */
  }

  #planning_text_right {
    bottom: 0%; /* Center it vertically */
    // right: 10%; /* Center it horizontally */
  }

  #planning_text_left {
    bottom: 0%; /* Center it vertically */
    // left: 10%; /* Center it horizontally */
  }
  #planning_text_left,
  #planning_text_right {
    width: 73px;
  }

  #research_text_right,
  #planning_text_right {
    right: 5%; /* Center it horizontally */
  }
  #research_text_left,
  #planning_text_left {
    left: 5%; /* Center it horizontally */
  }

  #research_text_right,
  #research_text_left,
  #planning_text_right,
  #planning_text_left {
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 184.615% */
  }
}
