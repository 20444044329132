@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Bungee&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Permanent+Marker&family=Rubik+Bubbles&display=swap");

.about-partners {
  // margin-top: 100px;
  // margin-bottom: 100px;
  background-color: black;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 64px;
  align-self: stretch;
  overflow: hidden;
}

.about-partners-title {
  color: #fff;
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  // line-height: 35px; /* 100% */
  line-height: 150%;
  text-transform: uppercase;
  // padding: 0;
}

@media (min-width: 1728px) {
  .about-partners-title {
    font-size: 48px;
    line-height: 32px;
  }

  .about-partners {
    padding: 150px 0px;
    gap: 64px;
  }
}

@media (min-width: 1280px) {
  .about-partners-title {
    font-size: 32px;
    line-height: 32px;
  }

  .about-partners {
    padding: 100px 0px;
    align-self: stretch;
  }
}

@media (max-width: 834px) and (max-width: 1279px) {
  .about-partners-title {
    font-size: 32px;
    line-height: 32px;
  }

  .about-partners {
    padding: 80px 0px;
    gap: 48px;
  }
}

@media (min-width: 390px) and (max-width: 833px) {
  .about-partners-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 32px; /* 160% */
  }

  .about-partners {
    display: flex;
    padding: 50px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    align-self: stretch;
  }
}

@media (max-width: 389px) {
  .about-partners-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 32px; /* 160% */
  }

  .about-partners {
    display: flex;
    padding: 50px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    align-self: stretch;
  }
}
