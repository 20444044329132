.card-deck-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-column: 1 / span 24;
  width: 100%;
  align-items: initial;
  align-content: initial;
  height: 420px ;
  // margin-bottom: 100px;
  padding-bottom: 100px;

  //margin-right: auto;
}

.card {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  gap: 10px;
}

.card-level-1,
.card-level-2 {
  position: absolute;
  top: 0;
  left: 0;
  transition: transform ease-in-out 1s;
}

// @media  screen and (min-width:390px) {
//   .card-deck-center{
//     align-items: initial;
//     align-content: initial;
//     height: 420px ;
//   }
// }

@media  screen and (min-width:834px) {
  .card-deck-center{
    align-items: initial;
    align-content: initial;
    height: 700px ;
  }
}
@media  screen and (min-width:1280px) {
  .card-deck-center{
    align-items: center;
    align-content: center;
    height: auto;
  }
}