.WholeContainer {
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  padding: 32px;
  gap: 32px;
  width: 90vw;
  height: auto;
  max-height: calc(100vh - 230px);
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  background: #1A1A1A;
}

.storiesExpandedImgContainer {
  background-color: transparent;
  height: auto;
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
}

.storiesExpandedImg {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 80vh;
  border-radius: 4px;
  object-fit: contain;
}

.storiesExpandedContent {
  color: white;
  flex: 1;
  overflow-y: auto;
  padding: 0px 32px 0px 32px;
  text-align: start;
  max-height: calc(100vh - 262px);
  // box-sizing: border-box;

  /* Custom scrollbar styling */
  scrollbar-width: thin;
  scrollbar-color: #f05a22 #ffffff; /* Always white track, orange thumb */
}

.storiesExpandedContent::-webkit-scrollbar {
  width: 12px;
}

.storiesExpandedContent::-webkit-scrollbar-track {
  background: #ffffff; /* Always white background for the track */
}

.storiesExpandedContent::-webkit-scrollbar-thumb {
  background-color: #f05a22; /* Orange color for the scrollbar thumb */
  border-radius: 6px;
  border: 3px solid #ffffff; /* Padding around the thumb, ensuring the white track is visible */
}


.storiesExpandedContentText {
  margin-right: 32px;
  color: rgba(255, 255, 255, 0.91);
  font-family: Inter;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3;
}

.storiesExpandedContentHeader {
  overflow: hidden;
  color: rgba(255, 255, 255, 0.91);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-top: 0px;
}

@media (max-width: 834px) {
  .WholeContainer {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    width: 83vw;
    height: auto;
    padding: 32px;
  }

  .storiesExpandedImgContainer {
    width: 100%;
    height: auto;
  }

  .storiesExpandedContent {
    width: 100%;
    padding: 0;
    max-height: 50vh;
  }

  .storiesExpandedContentText {
    margin-right: 32px;
    font-size: 1rem;
  }

  .storiesExpandedContentHeader {
    font-size: 32px;
  }
}

@media (max-width: 390px) {
  .WholeContainer {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    width: 85vw;
    left: -4vw;
    height: auto;
    padding: 32px;
  }

  .storiesExpandedImgContainer {
    width: 100%;
    height: auto;
  }

  .storiesExpandedContent {
    width: 90%;
    max-height: 50vh;
    padding: 0px 20px 0px 0px;
    // background-color: blue;
    align-self: center;
  }

  .storiesExpandedContentText {
    margin-right: 0px;
    font-size: 1rem;
  }

  .storiesExpandedContentHeader {
    font-size: 32px;
    margin-top: 0px;
  }
}
