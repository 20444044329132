@import url("https://fonts.googleapis.com/css2?family=BIZ+UDPGothic&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Bungee&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Permanent+Marker&family=Rubik+Bubbles&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Bungee&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Permanent+Marker&family=Rubik+Bubbles&display=swap");


.giving-back {
  // width: 100%;
  // height: 400px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
  padding: 100px 16px 90px 16px;
  align-items: flex-start;

  &-header {
    align-self: stretch;
    color: #fff;
    text-align: left;
     font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 44px */
    text-transform: uppercase;
  }

  &-sub {
    width: 357px;
    color: #eaeaea;
    text-align: left;
     font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 48px */
    &-orange {
      color: #f05a22;
    }
  }
}

// @media screen and (min-width: 390px) {
//   .giving-back {
//     padding: 100px 16px 90px 16px;
//     align-items: flex-start;
//     gap: 16px;

//     &-header {
//       font-size: 40px;
//       line-height: 110%; /* 44px */
//       text-align: left;
//     }

//     &-sub {
//       width: 357px;
//       color: #eaeaea;
//       font-size: 16px;
//       font-weight: 400;
//       text-align: left;
//     }
//   }
// }

@media screen and (min-width: 620px) {
  .giving-back {
    padding: 100px 16px 90px 16px;
    align-items: flex-start;
    gap: 16px;

    &-header {
      font-size: 40px;
      line-height: 110%; /* 44px */
      text-align: left;
    }

    &-sub {
      width: 580px;
      color: #eaeaea;
      font-size: 22px;
      font-weight: 400;
      text-align: left;
    }
  }
}

@media screen and (min-width: 834px) {
  .giving-back {
    display: flex;
    padding: 80px 24px 100px 24px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;

    &-header {
      width: 100%;
      font-size: 40px;
      line-height: 150%; /* 144px */
      text-align: center;
    }

    &-sub {
      width: 786px;
      color: #eaeaea;
      font-size: 24px;
      font-weight: 400;
      text-align: center;
      line-height: 150%; /* 144px */
    }
  }
}

@media screen and (min-width: 1280px) {
  .giving-back {
    display: flex;
    padding: 100px 0px 168px 0px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;

    &-header {
      width: 100%;
      font-size: 96px;
      line-height: 150%; /* 48px */
      text-align: center;
    }

    &-sub {
      width: 1050px;
      color: #eaeaea;
      font-size: 32px;
      font-weight: 400;
      text-align: center;
      line-height: 150%; /* 48px */
    }
  }
}

@media screen and (min-width: 1728px) {
  .giving-back {
    display: flex;
    padding: 210px 0px;
    flex-direction: column;
    align-items: center;
    gap: 64px;
    align-self: stretch;

    &-header {
      width: 100%;
      font-size: 128px;
      line-height: 150%; /* 48px */
      text-align: center;
    }

    &-sub {
      width: 1528px;
      color: #eaeaea;
      font-size: 44px;
      font-weight: 400;
      text-align: center;
      line-height: 150%; /* 48px */
    }
  }
}
