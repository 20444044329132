@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Bungee&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Permanent+Marker&family=Rubik+Bubbles&display=swap");

.home-bottomtext-Container {
  display: flex;
    padding: 50px 16px 100px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}


#homeBottomTitte {
  align-self: stretch;
  color: #fff;
   font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 44px */
  text-transform: uppercase;
  text-align: left;
  justify-content: left;
}

#homeBottomP {
   font-family: Montserrat;
  width: 100%;
  color: #eaeaea;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  text-align: left;
}

@media screen and (max-width: 469px) {
  .home-bottomtext-Container {
    display: flex;
    padding: 50px 16px 100px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }

  #homeBottomTitte {
    font-size: 40px;
    line-height: 110%; /* 44px */
    text-align: left;
    justify-content: left;
  }

  #homeBottomP {
    width: 358px;
    color: #eaeaea;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    text-align: left;
  }
}

@media screen and (min-width: 470px) {
  .home-bottomtext-Container {
    display: flex;
    padding: 50px 32px 100px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    justify-content: center;
    align-items: center;
  }

  #homeBottomTitte {
    font-size: 50px;
    line-height: 110%; /* 44px */
    text-align: left;
    justify-content: left;
    text-align: center;
  }

  #homeBottomP {
    width: 400px;
    color: #eaeaea;
    font-size: 17px;
    text-align: center;
  }
}


@media screen and (min-width: 620px) {
  .home-bottomtext-Container {
    display: flex;
    padding: 50px 32px 100px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    justify-content: center;
    align-items: center;
  }

  #homeBottomTitte {
    font-size: 50px;
    line-height: 110%; /* 44px */
    text-align: left;
    justify-content: left;
    text-align: center;
  }

  #homeBottomP {
    width: 540px;
    color: #eaeaea;
    font-size: 17px;
    text-align: center;
  }
}

@media screen and (min-width: 834px) {
  .home-bottomtext-Container {
    display: flex;
    padding: 80px 24px 150px 24px;
    flex-direction: column;
    align-items: center;
    gap: 64px;
    align-self: stretch;
  }

  #homeBottomTitte {
    font-size: 64px;
    line-height: 150%; /* 96px */
    text-align: center;

  }

  #homeBottomP {
    width: 786px;
    font-size: 24px;
    line-height: 150%; /* 24px */
    text-align: center;
  }
}

@media screen and (min-width: 1280px) {
  .home-bottomtext-Container {
    display: flex;
    padding: 64px 0px 164px 0px;
    flex-direction: column;
    align-items: center;
    gap: 64px;
    align-self: stretch;
  }

  #homeBottomTitte {
    font-size: 96px;


  }

  #homeBottomP {
    width: 979px;
    font-size: 32px;

  }
}

@media screen and (min-width: 1280px) {
  .home-bottomtext-Container {
    display: flex;
    padding: 64px 0px 164px 0px;
    flex-direction: column;
    align-items: center;
    gap: 64px;
    align-self: stretch;
  }

  #homeBottomTitte {
    font-size: 96px;


  }

  #homeBottomP {
    width: 979px;
    font-size: 32px;

  }
}

@media screen and (min-width: 1728px) {
  .home-bottomtext-Container {
    display: flex;
    padding: 210px 0px;
    flex-direction: column;
    align-items: center;
    gap: 132px;
    align-self: stretch;
  }

  #homeBottomTitte {
    font-size: 128px;


  }

  #homeBottomP {
    width: 1528px;
    font-size: 44px;

  }
}