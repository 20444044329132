body {
  background-color: black;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: GothamTwo;
  src:
    local("GothamTwo"),
    url("/assets/fonts/gotham/GothamLight.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: GothamTwo;
  src:
    local("GothamTwo"),
    url("/assets/fonts/gotham/GothamLightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: GothamTwo;
  src:
    local("GothamTwo"),
    url("/assets/fonts/gotham/GothamMedium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: GothamTwo;
  src:
    local("GothamTwo"),
    url("/assets/fonts/gotham/GothamMediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: GothamTwo;
  src:
    local("GothamTwo"),
    url("/assets/fonts/gotham/GothamBold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: GothamTwo;
  src:
    local("GothamTwo"),
    url("/assets/fonts/gotham/GothamBoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: GothamTwo;
  src:
    local("GothamTwo"),
    url("/assets/fonts/gotham/GothamBook.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
