@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Bungee&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Permanent+Marker&family=Rubik+Bubbles&display=swap");

.impact-banner {
  display: flex;
  padding: 100px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  margin-top: 70px;

  &-title {
    align-self: stretch;
    color: #fff;
    text-align: center;
     font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 44px */
    text-transform: uppercase;
    line-height: 110%; /* 44px */
    text-align: left;
  }

  &-subheading {
    width: 358px;
    color: #eaeaea;
    text-align: center;
     font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 48px */
    text-align: left;
  }
}

// @media screen and (min-width: 390px) {
//   .impact-banner {
//     display: flex;
//     padding: 100px 16px;
//     flex-direction: column;
//     align-items: flex-start;
//     gap: 16px;
//     align-self: stretch;

//     &-title {
//       font-size: 40px;
//       line-height: 110%; /* 44px */
//       line-height: 110%; /* 44px */
//       text-align: left;
//     }

//     &-subheading {
//       width: 358px;
//       color: #eaeaea;
//       font-size: 16px;
//       text-align: left;
//     }
//   }
// }

// @media screen and (min-width: 470px) {
//   .impact-banner {
//     padding: 100px 16px;
//     gap: 16px;

//     &-title {
//       font-size: 45px;
//       line-height: 110%; /* 44px */
//       justify-content: flex-start;
//     }

//     &-subheading {
//       width: 400px;
//       color: #eaeaea;
//       font-size: 20px;
//       text-align: center;
//     }
//   }
// }


@media screen and (min-width: 620px) {
  .impact-banner {
    padding: 100px 16px;
    gap: 16px;
    align-items: center;

    &-title {
      font-size: 50px;
      line-height: 110%; /* 44px */
      justify-content: flex-start;
      text-align: center;
    }

    &-subheading {
      width: 600px;
      color: #eaeaea;
      font-size: 20px;
      justify-content: flex-start;
      text-align: center;
    }
  }
}

@media screen and (min-width: 834px) {
  .impact-banner {
    display: flex;
    padding: 80px 24px 100px 24px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    margin: 0;

    &-title {
      font-size: 64px;
      line-height: 150%; /* 96px */
    }

    &-subheading {
      width: 786px;

      color: #eaeaea;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 36px */
      text-align: center;
    }
  }
}

@media screen and (min-width: 1280px) {
  .impact-banner {
    width: 100%;
    padding: 100px 0px 168px 0px;
    gap: 24px;

    &-title {
      font-size: 96px;
      line-height: 150%; /* 96px */
    }

    &-subheading {
      width: 1050px;
      color: #eaeaea;
      font-size: 32px;
    }
  }
}

@media screen and (min-width: 1728px) {
  .impact-banner {
    width: 100%;
    padding: 210px 0px;
    gap: 64px;

    &-title {
      font-size: 128px;
      line-height: 150%; /* 96px */
    }

    &-subheading {
      width: 1528px;
      color: #eaeaea;
      font-size: 44px;
    }
  }
}
