@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Bungee&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Permanent+Marker&family=Rubik+Bubbles&display=swap");

#footerDiv {
  position: static;
  left: 0;
  bottom: 0;
  width: 100%;
  background-image: url("../../images/bg_l.png");
  background-size: cover; /* Increases or decreases the zoom level */
  background-position: center;
  background-repeat: no-repeat;
}

#contactHeader {
  color: #fff;
  font-family: Montserrat;
  font-style: normal;
  line-height: 66px; /* 140% */
  text-transform: uppercase;
}

#officeHeader,
#phoneNumHeader,
#emailHeader {
  align-self: stretch;
  color: rgba(255, 255, 255, 0.6);
  font-family: Inter;
  font-style: normal;
}

#officeAddress,
#phoneNumber,
#emailInfo {
  align-self: stretch;
  color: #fff;
  font-family: Inter;
  font-style: normal;
}

#copyrightText {
  color: #fff;
  font-family: Inter;
  font-style: normal;
}

@media (min-width: 1728px) {
  #contactHeader {
    width: 50%;
    font-size: 60px;
    font-weight: 500;
    line-height: 150%;
    text-align: left;
  }
  #copyrightText {
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
  }
  #copyrightIcon {
    width: 16px;
    height: 16px;
  }

  #footerDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 64px;
    align-self: stretch;
  }

  .footerInfo {
    width: 45%;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .infoText {
    display: flex;
    padding: 100px 0px 0px 100px;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    justify-content: space-between;
    align-self: stretch;
  }

  .officeInfo-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    text-align: left;
    width: 75%;
  }
  .copyright-container {
    display: flex;
    padding: 0px 0px 40px 100px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
  }
  .image-copy-container {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  #officeAddress,
  #phoneNumber,
  #emailInfo {
    font-size: 28px;
  }

  #officeHeader,
  #phoneNumHeader,
  #emailHeader {
    font-size: 24px;
    font-weight: 500;
    line-height: normal;
  }

  #copyrightText {
    font-size: 24px;
  }
  #copyrightIcon {
    width: 16px;
    height: 16px;
  }
}

@media (min-width: 1280px) and (max-width: 1727px) {
  #contactHeader {
    width: 45%;
    // height: 66px;
    font-size: 45px;
    font-weight: 500;
    line-height: 150%;
    text-align: left;
  }
  #copyrightText {
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
  }
  #copyrightIcon {
    width: 16px;
    height: 16px;
  }

  #footerDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 64px;
  }

  .footerInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }

  .infoText {
    display: flex;
    padding: 84px 32px 0px 32px;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    justify-content: space-between;
    align-self: stretch;
  }

  .officeInfo-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    text-align: left;
    width: 75%;
  }
  .copyright-container {
    display: flex;
    padding: 0px 32px 20px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
  }
  .image-copy-container {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  #officeAddress,
  #phoneNumber,
  #emailInfo {
    font-size: 20px;
  }

  #officeHeader,
  #phoneNumHeader,
  #emailHeader {
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    align-self: stretch;
  }

  #copyrightText {
    font-size: 14px;
  }
  #copyrightIcon {
    width: 16px;
    height: 16px;
  }
}

@media (min-width: 834px) and (max-width: 1279px) {
  #officeAddress {
    font-size: 10px;
    align-self: stretch;
  }

  #officeHeader {
    font-size: 10px;
    align-self: stretch;
  }
  #phoneNumber {
    font-size: 10px;
    width: 285px;
  }

  #phoneNumHeader {
    font-size: 10px;
    align-self: stretch;
  }
  #emailHeader {
    font-size: 10px;
    align-self: stretch;
  }

  #emailInfo {
    font-size: 10px;
    width: 285px;
  }
  #contactHeader {
    // width: 408px;
    height: 66px;
    font-size: 31px;
    font-weight: 500;
    line-height: 150%;
    text-align: left;
  }
  #copyrightText {
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
  }
  #copyrightIcon {
    width: 16px;
    height: 16px;
  }

  #footerDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;
  }

  .footerInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;
    // width: 50%;
  }

  .infoText {
    padding: 28px 24px 0px 24px;
    display: flex;
    align-items: flex-start;
    gap: 44px;
  }

  .officeInfo-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    text-align: left;
  }
  .copyright-container {
    display: flex;
    padding: 0px 32px 28px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .image-copy-container {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  #officeAddress {
    font-size: 18px;
  }

  #officeHeader {
    font-size: 16px;
  }
  #phoneNumber {
    // width: 100%;
    font-size: 18px;
  }

  #phoneNumHeader {
    font-size: 16px;
  }
  #emailHeader {
    font-size: 16px;
  }

  #emailInfo {
    font-size: 18px;
  }
  #contactHeader {
    // width: 408px;
    height: 75px;
  }
  #copyrightText {
    font-size: 12px;
  }
  #copyrightIcon {
    width: 16px;
    height: 16px;
  }
}

@media (max-width: 833px) {
  #officeAddress {
    font-size: 10px;
    align-self: stretch;
  }

  #officeHeader {
    font-size: 10px;
    align-self: stretch;
  }
  #phoneNumber {
    font-size: 10px;
    width: 285px;
  }

  #phoneNumHeader {
    font-size: 10px;
    align-self: stretch;
  }
  #emailHeader {
    font-size: 10px;
    align-self: stretch;
  }

  #emailInfo {
    font-size: 10px;
    width: 285px;
  }
  #contactHeader {
    width: 358px;
    height: 66px;
    font-size: 30px;
    font-weight: 500;
    line-height: 130%;
  }
  #copyrightText {
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
  }
  #copyrightIcon {
    width: 16px;
    height: 16px;
  }

  #footerDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;
  }

  .footerInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    width: 50%;
  }

  .infoText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    text-align: left;
    padding: 28px 16px 0px 16px;
  }

  .officeInfo-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    align-self: stretch;
  }
  .copyright-container {
    display: flex;
    padding: 0px 32px 28px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .image-copy-container {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}
