@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Bungee&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Permanent+Marker&family=Rubik+Bubbles&display=swap");

.mainContainer {
  display: flex;
  margin-top: 168px;
  flex-direction: column;
  align-items: center;
  // gap: 64px;
  // align-self: stretch;

  // margin-bottom: 150px;
}
.subContainer1 {
  display: flex;
  padding: 100px 0px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}
.Title {
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 96px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 144px */
  text-transform: uppercase;
}

.Description {
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 48px */
  width: 1050px;
}

.subContainer2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 804px;
  height: 508px;
}

.ourProcessBorder {
  position: relative;
  justify-content: center;
  align-items: center;
  width: 1280px;
}

.ball-item {
  // margin-right: 10px;
  transform: rotate(45deg);

  position: absolute;
  top: 126px;
  left: 170px;
}

.ball-item2 {
  // bottom: 20%;
  transform: rotate(45deg);
  position: absolute;
  top: 126px;
  right: 170px;
}

.proccess-left {
  position: absolute;
  top: 250px;
  right: 298px;
  // z-index: 2;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px; /* 125% */
  text-transform: uppercase;
}

.proccess-right {
  position: absolute;
  top: 250px;
  left: 305px;
  // z-index: 2;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px; /* 125% */
  text-transform: uppercase;
}

.proposalTitle {
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 100% */
  text-transform: uppercase;
}

.endProduct {
  position: relative;

  color: #fff;
  text-align: center;
   font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 100% */
  text-transform: uppercase;
  margin-top: 10px;
}

// #process_top_square_outline {
//   width: 452px;
//   transform: rotate(45deg);
//   margin-top: 180px;
//   position: relative; /* Make it a reference point for absolute positioning */
//   z-index: 2;
// }

#test_text_right,
#test_text_left {
  color: #434343;
  font-size: 24px;
  font-weight: 800;
  position: absolute; /* Position it relative to #process_top_square_outline */
  transition: color 2s ease-in-out; /* Smooth transition */
}

#test_text_left {
  bottom: 0%; /* Center it vertically */
  left: -30%; /* Center it horizontally */
}
#test_text_right {
  bottom: 0%; /* Center it vertically */
  right: -30%; /* Center it horizontally */
}

.bottom_diamond_container {
  position: relative;
  margin-bottom: 44%;
}

#develop_text_right,
#develop_text_left,
#test_text_right,
#test_text_left {
  z-index: 2;
}

#top_arrow_diamond {
  position: absolute;
  transform: rotate(45deg);
  top: 33%;
  left: 44%;
}

#firstCircle {
  position: absolute;
  // top: 3%;
  left: 42%;
  width: 54px;
  height: 54px;
}

#secondCircle {
  position: absolute;
  // bottom: -26%;
  bottom: 3%;
  left: 44%;
  width: 54px;
  height: 54px;
}

#thirdCircle {
  position: absolute;
  bottom: 0;
  left: 44%;
  width: 54px;
  height: 54px;
}

#bottom_letters_container {
  position: absolute;
  bottom: 4%;
  transform: rotate(45deg);
  left: 5%;
  z-index: 3;
}

#process_bottom_square_outline {
  width: 452px;
  transform: rotate(45deg);
  margin-top: 180px;
  position: relative; /* Make it a reference point for absolute positioning */
  z-index: 2;
}

#bottom_arrows_overlay {
  position: absolute;
  top: 10%;
  left: -24%;
  width: 150%;
  height: 107%;
}

@media (min-width: 1728px) {
  .subContainer1 {
    padding: 210px 0px;
    gap: 64px;
  }

  .Title {
    font-size: 128px;
    line-height: 150%;
  }

  .Description {
    font-size: 44px;
    // max-width: 1050px;
    width: 1528px;
    line-height: 150%; /* 66px */
  }

  .mainContainer {
    margin-top: 0;

    margin-bottom: 0;
  }

  .proposalTitle,
  .endProduct {
    font-size: 58px;
    font-weight: 700;
    line-height: 120%; /* 69.6px */
  }

  #firstCircle,
  #secondCircle,
  #thirdCircle {
    width: 100px;
    height: 100px;
    left: 48%;
  }

  #firstCircle {
    position: relative;
    top: 0;
    left: 0;
  }
  #secondCircle {
    position: relative;
    bottom: 80px;
    left: 0;
  }

  #thirdCircle {
    position: relative;
    left: 0;
    bottom: 40px;
  }

  // #process_top_square_outline {
  //   width: 230px;
  //   margin-top: 100px;
  // }

  #top-dimaond-container {
    margin-top: 150px;
  }

  #bottom-diamond-container {
    margin-top: 50px;
  }
}

@media (min-width: 1440px) and (max-width: 1727px) {
  .subContainer1 {
    padding: 100px 0px;
    gap: 24px;
  }

  .Title {
    font-size: 96px;
    line-height: 150%; /* 144px */
  }

  .Description {
    font-size: 32px;
    // max-width: 1050px;\
    width: 80%;
  }

  .mainContainer {
    margin-top: 0;

    margin-bottom: 0;
  }

  .proposalTitle,
  .endProduct {
    font-size: 32px;
    line-height: 32px; /* 160% */
  }

  #firstCircle,
  #secondCircle,
  #thirdCircle {
    width: 54px;
    height: 54px;
    left: 48%;
  }

  #firstCircle {
    position: relative;
    left: 0;
    top: 15px;
  }
  #secondCircle {
    position: relative;
    bottom: 20px;
    left: 0;
    // bottom: -90%;
  }

  #thirdCircle {
    position: relative;
    left: 0;
    bottom: 20px;
  }

  // #process_top_square_outline {
  //   width: 230px;
  //   margin-top: 100px;
  // }

  #top-dimaond-container {
    margin-top: 80px;
  }

  #bottom-diamond-container {
    margin-top: 50px;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .subContainer1 {
    padding: 100px 0px;
    gap: 24px;
  }

  .Title {
    font-size: 96px;
    line-height: 150%; /* 144px */
  }

  .Description {
    font-size: 32px;
    // max-width: 1050px;\
    width: 80%;
  }

  .mainContainer {
    margin-top: 0;

    margin-bottom: 0;
  }

  .proposalTitle,
  .endProduct {
    font-size: 32px;
    line-height: 32px; /* 160% */
  }

  .proposalTitle {
    position: relative;
    top: 50px;
  }

  #firstCircle,
  #secondCircle,
  #thirdCircle {
    width: 54px;
    height: 54px;
    left: 48%;
  }

  #firstCircle {
    position: relative;
    left: 0;
    top: 70px;
  }
  #secondCircle {
    position: relative;
    bottom: 20px;
    left: 0;
    // bottom: -90%;
  }

  #thirdCircle {
    position: relative;
    left: 0;
    bottom: 20px;
  }

  // #process_top_square_outline {
  //   width: 230px;
  //   margin-top: 100px;
  // }

  #top-dimaond-container {
    margin-top: 150px;
  }

  #bottom-diamond-container {
    margin-top: 50px;
  }
}

@media (min-width: 834px) and (max-width: 1279px) {
  .subContainer1 {
    display: flex;
    padding: 80px 24px 100px 24px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;
  }

  .Title {
    font-size: 40px;
    font-weight: 700;
    line-height: 110%; /* 44px */
  }

  .Description {
    font-size: 24px;
    font-weight: 400;
    line-height: 150%; /* 36px */
    // max-width: 786px;
    width: 98%;
    text-align: center;
  }

  .mainContainer {
    margin-top: 0;

    // align-self: stretch;

    // margin-bottom: 150px;
    margin-bottom: 0;
  }

  .proposalTitle,
  .endProduct {
    font-size: 28px;
    line-height: 32px; /* 160% */
  }

  .endProduct {
    margin-top: 20px;
  }

  #firstCircle,
  #secondCircle,
  #thirdCircle {
    width: 32px;
    height: 32px;
    // left: 48%;
  }

  #firstCircle {
    position: relative;
    top: 20px;
    left: 0;
    // top: 32.5%;
  }
  #secondCircle {
    position: relative;
    left: 0;
    bottom: 0;
  }

  #thirdCircle {
    position: relative;
    left: 0;
  }

  // #process_top_square_outline {
  //   width: 230px;
  //   margin-top: 100px;
  // }

  #top-dimaond-container {
    margin-top: 50px;
  }

  #bottom-diamond-container {
    margin-top: 40px;
  }
}

// Media query for 390px width and 844px height
@media (min-width: 390px) and (max-width: 833px) {
  .subContainer1 {
    display: flex;
    padding: 100px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    // width: 358px;
    margin-bottom: 0;
  }

  .Title {
    color: #fff;
    text-align: center;
  font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 44px */
    text-transform: uppercase;
  }

  .Description {
    color: #eaeaea;
  font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    max-width: 358px;
    text-align: left;
  }

  .proposalTitle {
    color: #fff;
    text-align: center;
  font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 160% */
    text-transform: uppercase;
  }

  .mainContainer {
    margin-top: 0;

    gap: 0;
    // align-self: stretch;

    // margin-bottom: 150px;
    margin-bottom: 0;
  }

  .proposalTitle,
  .endProduct {
    color: #fff;
    text-align: center;
  font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 160% */
    text-transform: uppercase;
  }

  #firstCircle,
  #secondCircle,
  #thirdCircle {
    width: 32px;
    height: 32px;
    left: 45%;
  }
  #firstCircle {
    position: relative;
    top: 20px;
    left: 0;
    // top: 32.5%;
  }

  #secondCircle {
    position: relative;
    left: 0;
    bottom: 0;
  }

  #thirdCircle {
    position: relative;
    left: 1%;
  }

  #top-dimaond-container {
    margin-top: 0;
  }

  #bottom-diamond-container {
    margin-top: 0px;
  }
}

// Media query for 390px width and 844px height
@media (max-width: 389px) {
  .subContainer1 {
    display: flex;
    padding: 100px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    // width: 358px;
    margin-bottom: 0;
  }

  .Title {
    color: #fff;
    text-align: center;
  font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 44px */
    text-transform: uppercase;
  }

  .Description {
    color: #eaeaea;
  font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    width: 90%;
    text-align: left;
  }

  .proposalTitle {
    color: #fff;
    text-align: center;
  font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 160% */
    text-transform: uppercase;
  }

  .mainContainer {
    margin-top: 0;

    gap: 0;
    // align-self: stretch;

    // margin-bottom: 150px;
    margin-bottom: 0;
  }

  .proposalTitle,
  .endProduct {
    color: #fff;
    text-align: center;
  font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 160% */
    text-transform: uppercase;
  }

  #firstCircle,
  #secondCircle,
  #thirdCircle {
    width: 32px;
    height: 32px;
    left: 45%;
  }
  #firstCircle {
    top: 46%;
  }

  #secondCircle {
    bottom: 5%;
  }

  #thirdCircle {
    position: relative;
    left: 1%;
  }

  #top-dimaond-container {
    margin-top: 0;
  }

  #bottom-diamond-container {
    margin-top: 0px;
  }
}
