@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Bungee&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Permanent+Marker&family=Rubik+Bubbles&display=swap");


@font-face {
  font-family: "Gotham";
  src: url(../../../public/GothamBold.otf) format("opentype");
}

/* Keyframes for fade and translate animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.servicesHero {
  margin-top: 100px;
  position: relative;
  width: 100%;
  min-height: 640px;
  background-color: #000000;
  margin-bottom: 4%;
}

.overviewServiceContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0;
}

.overviewServiceHeader {
  color: #fff;
   font-family: Montserrat;
  font-size: 96px;
  font-style: normal;
  font-weight: 700;
  margin: 0;
  line-height: 150%; /* 144px */
  text-transform: uppercase;
  align-self: stretch;
}

.overviewServiceDescription {
  color: #FFF;
   font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 48px */
  max-width: 1200px;
  margin: 0 32px 100px 32px;
}

.boldedServicesText {
  font-weight: 700;
  color: #F05A22;
}

@media screen and (max-width: 1279px) {
  .servicesHero {
    margin-top: 80px;
  }

  .overviewServiceHeader {
    font-size: 64px;
    line-height: 150%; /* 96px */
    margin-top: 20px;
  }

  .overviewServiceDescription {
    font-size: 24px;
    line-height: 150%; /* 36px */
    max-width: 810px;
    // margin: 0 24px 25px 24px;
  }
}

@media screen and (max-width: 833px) {
  .overviewServiceContainer {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
  }

  .overviewServiceHeader {
    font-size: 40px;
    line-height: 110%; /* 96px */
    margin-left: 16px;
  }

  .overviewServiceDescription {
    font-size: 16px;
    margin: 16px 16px 40px 16px;
  }
}

@media screen and (max-width: 390px) {
  .overviewServiceDescription {
    margin: 0 16px;
  }
}

@media screen and (min-width: 1728px) {
  .overviewServiceHeader {
    font-size: 128px;
    margin-top: 210px;
  }

  .overviewServiceDescription {
    font-size: 44px;
    margin: 64px 0 210px 0;
    max-width: 1528px;
  }
}