.lusion-card-wrapper {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 8px;
  gap: 10px;
  perspective: 1000px; /* Added for 3D perspective */
}

.card {
  position: relative; /* Changed to relative to position content inside */
  width: 356px;
  height: 246px;
  border-radius: 8px;
}

.content {
  // position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  transition: transform 5s;
  transform-style: preserve-3d; /* Added preserve-3d */
  position: relative;
}

.front,
.back {
  position: absolute;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  // border: 3px solid white;

  // background: white;
  // background-color: white;
  text-align: center;
  backface-visibility: hidden; /* Un-commented */
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.front-content {
  position: relative;
  justify-items: center;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  background-color: none;

  img {
    width: inherit;
    height: inherit;
    object-fit: cover;
    flex-shrink: 0;
    top: 0;
    left: 0;
    border-radius: 8px;
    position: absolute;
  }
}

// this will hide the back side of the card

.back {
  color: black;
  transform: rotateY(180deg); /* Un-commented */
  width: 100%;
  height: 100%;
  flex-shrink: 0;
}

.card-sub-heading {
  display: flex;
  padding: 4px 4px 4px 16px;
  justify-content: center;
  animation: 5s fadeInUp;
  align-items: center;
  gap: 12px;
  border-radius: 100px;
  background: rgba(255, 255, 255, 0.22);
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  border: none;
}

@keyframes fadeInUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.service {
  width: 356px;
  height: 246px;
  width: inherit;
  height: inherit;
  display: inline-flex;
  padding: 0px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: black;
  border-radius: 8px;
  text-align: left;
}

.service-title {
  align-self: stretch;
  color: rgba(255, 255, 255, 0.9);
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 48px */
  font-size: 28px;
  margin-bottom: 16px;
}

.service-subcontent {
  font-size: 15px;
  width: 100%;
  margin-bottom: 32px;
    color: #fff;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28px */
  margin-bottom: 32px;
}

.card-sub-carousel {
  width: 0;
  height: 0;
  overflow: hidden;
  flex-shrink: 0;
}

.card-sub-heading:hover {
  cursor: pointer;
}
.card-sub-heading {
  font-size: 15px;
}
.card-circle-svg {
  width: 32px;
  height: 32px;
}

// @media screen and (min-width: 390px) {
//   .card,
//   .service {
//     width: 356px;
//     height: 246px;
//   }

//   .service {
//     padding: 0px 12px;
//     &-title {
//       font-size: 28px;
//       margin-bottom: 16px;
//     }

//     &-subcontent {
//       font-size: 15px;
//       width: 100%;
//       margin-bottom: 32px;
//     }
//   }
//   .card-sub-carousel {
//     width: 0;
//     height: 0;
//     overflow: hidden;
//     flex-shrink: 0;
//   }
//   .card-sub-heading {
//     font-size: 15px;
//   }
//   .card-circle-svg {
//     width: 32px;
//     height: 32px;
//   }
// }

@media screen and (min-width: 834px) {
  .card,
  .service {
    width: 748px;
    height: 374px;
  }

  .service {
    padding: 0px 32px;
    &-title {
      font-size: 36px;
    }

    &-subcontent {
      font-size: 20px;
      width: 100%;
    }
  }
  .card-sub-carousel {
    width: 684px;
    height: 24px;
    overflow: visible;
    flex-shrink: 0;
    margin-bottom: 48px;
  }
  .card-sub-heading {
    font-size: 20px;
  }
  .card-circle-svg {
    width: 32px;
    height: 32px;
  }
}

// @media screen and (min-width: px)  {
//   .card,
//   .service {
//     width: 450px;
//     height: 348px;
//   }

//   .service {
//     padding: 0px 12px;
//     &-title {
//       font-size: 28px;
//     }

//     &-subcontent {
//       font-size: 18px;
//       width: 100%;
//     }
//   }
//   .card-sub-carousel {
//     width: 425px;
//     height: 24px;
//     flex-shrink: 0;
//   }
//   .card-sub-heading {
//     font-size: 18px;
//   }
//   .card-circle-svg {
//     width: 32px;
//     height: 32px;
//   }
// }

@media screen and (min-width: 1280px)  {
  .card,
  .service {
    width: 592px;
    height: 348px;
  }

  .service {
    padding: 0px 24px;
    &-title {
      font-size: 32px;
    }

    &-subcontent {
      font-size: 20px;
      width: 100%;
    }
  }
  .card-sub-carousel {
    width: 544px;
    height: 24px;
    flex-shrink: 0;
  }
  .card-sub-heading {
    font-size: 18px;
  }
  .card-circle-svg {
    width: 32px;
    height: 32px;
  }
}

@media screen and (min-width: 1728px) {
  .card,
  .service {
    width: 748px;
    height: 460px;
  }

  .service {
    padding: 0px 32px;
    &-title {
      font-size: 44px;
    }

    &-subcontent {
      font-size: 28px;
      width: 100%;
    }
  }
  .card-sub-carousel {
    width: 684px;
    height: 38px;
    flex-shrink: 0;
  }
  .card-sub-heading {
    font-size: 28px;
  }
  .card-circle-svg {
    width: 48px;
    height: 48px;
  }
}
