.bgvidcontainer {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  object-fit: cover; /* Ensures video covers the whole area while maintaining aspect ratio */
  object-position: center; /* Keeps the video content centered */
  box-sizing: border-box;
}
.impact-vid {
  width: 100%;
  height: 100%; /* Ensure the video covers the container */
  display: block;
  object-fit: cover; /* Ensures the video scales properly */
  object-position: center; /* Centers the video content */
  // position: absolute; /* Position absolutely within the container */
}

// @media screen and (min-width:390px) {
//   .bgvidcontainer{
//     height: 298px;
//   }
// }

@media screen and (min-width: 834px) {
  .bgvidcontainer {
    height: 260px;
  }
}

@media screen and (min-width: 1280px) {
  .bgvidcontainer {
    height: 400px;
  }
}

@media screen and (min-width: 1280px) {
  .bgvidcontainer {
    height: 400px;
  }
}

@media screen and (min-width: 1728px) {
  .bgvidcontainer {
    height: 540px;
  }
}
