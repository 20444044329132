@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Bungee&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Permanent+Marker&family=Rubik+Bubbles&display=swap");

.home-card-service {
  display: inline-flex;
  padding: 100px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
   font-family: Montserrat;
}

.home-card-service-title {
  width: 100%;
  align-self: stretch;
  color: #fff;
  text-align: center;
   font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 144px */
  text-transform: uppercase;
  font-size: 40px;
}

.home-card-service-content {
  color: #eaeaea;
  text-align: center;
   font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 48px */
  width: 358px;
  font-size: 17px;
  line-height: 110%; /* 44px */
}

.home-card-bold-content {
  font-weight: 700;
}


// @media screen and (min-width: 390px) {
//   .home-card-service {
//     display: inline-flex;
//     padding: 100px 16px;
//     flex-direction: column;
//     align-items: flex-start;
//     gap: 32px;
//   }

//   .home-card-service-title {
//     width: 100%;
//     font-size: 40px;
//     font-weight: 700;
//   }

//   .home-card-service-content {
//     width: 358px;
//     font-size: 17px;
//     line-height: 110%; /* 44px */
//   }

//   .home-card-bold-content {
//     font-weight: 700;
//   }
// }


@media screen and (min-width: 834px) {
  .home-card-service {
    display: flex;
    padding: 100px 24px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;
  }

  .home-card-service-title {
    width: 100%;
    font-size: 64px;
    font-weight: 700;
  }

  .home-card-service-content {
    width: 786px;
    font-size: 24px;
  }

  .home-card-bold-content {
    font-weight: 700;
  }
}

@media screen and (min-width: 1728px) {
  .home-card-service {
    display: flex;
    padding: 150px 100px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  .home-card-service-title {
    width: 100%;
    color: #fff;
    text-align: center;
    font-size: 128px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 192px */
    text-transform: uppercase;
  }

  .home-card-service-content {
    width: 1528px;
    color: #eaeaea;
    text-align: center;
    font-size: 44px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 66px */
  }

  .home-card-bold-content {
    font-weight: 700;
  }
}