@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Bungee&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Permanent+Marker&family=Rubik+Bubbles&display=swap");

.slick-prev,
.slick-next {
  display: none !important;
}

.slider-container {
  text-align: center;
  // margin-top: 100px;
  background: #000;
  color: #fff;
  position: relative;
  width: 100%;
}

.slider-title {
  color: #fff;
  text-align: center;
   font-family: Montserrat;
  font-size: 96px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 144px */
  text-transform: uppercase;
  margin-bottom: 0px;
  // height: 144px;
}

.team-slide {
  padding: 5px;
  outline: none;
  // transition:
  //   transform 0.3s ease,
  //   filter 0.3s ease,
  //   top 0.3s ease;
  position: relative;
  top: 150px;
}

.center-slide {
  top: 0;
}

.slick-center .team-slide {
  transform: scale(1);
  top: 0;
}

.slick-center .slide-img {
  width: 250px;
  height: 370px;
  filter: none;
  transition: filter 0.8s ease-in-out;
}

.slide-img {
  width: 180px;
  height: 220px;
  margin: auto;
  // transition: filter 1s ease-in-out;
  filter: grayscale(100%);
  object-fit: cover;
  object-position: top; /* Align the image to the bottom */
}

.name {
  color: #fff;
   font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 116.667% */
  text-transform: uppercase;
  text-align: start;
}

.team-role {
  // text-align: center;
  // height: 50px;
  // margin: 2%;
  // margin-bottom: -10px;

  color: rgba(255, 255, 255, 0.75);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.2px; /* 113.75% */
  text-align: start;
  margin-top: 0px;
}

.name-role-container {
  padding-left: 5px;
  padding-top: 10px;
}

.bars-animation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
}

.custom-arrow {
  width: 40px;
  height: 40px;
  background: #333;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 10px;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:hover {
    background: #555;
  }

  svg {
    width: 20px;
    height: 20px;
  }
}

.animated-bars {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.bar,
.middleBar,
.sideBar {
  display: inline-block;
  color: #d9d9d9;
  font-size: 22px;
  margin: 0 5px;
  cursor: pointer;
  // width: 2px;
  // transition: transform 0.3s ease;
  // animation: wave 2s infinite ease-in-out;

  &:hover {
    transform: scale(2);
    color: #f05a22; /* Change color when hovered */
  }
}

.middleBar {
  transform: scale(2);
}

.sideBar {
  transform: scale(1.4);
}

@keyframes wave {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.dot {
  color: #fff;
  font-size: 20px;
  margin: 0 5px;
}
.slick-list {
  right: 0.4%;
}

@media (min-width: 1728px) {
  .slider-title {
    font-size: 128px;
  }

  // .slick-center .slide-img {
  //   width: 418px;
  //   height: 528px;
  //   filter: none;
  // }

  // .slide-img {
  //   width: 250px;
  //   height: 250px;
  //   margin: auto;
  //   transition: filter 0.3s ease;
  //   filter: grayscale(100%);
  //   object-fit: cover;
  // }

  .name-role-container {
    padding-left: 35px;
    padding-top: 10px;
  }

  .bars-animation-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 64px;
  }

  .animated-bars {
    display: flex;
    align-items: center;
    margin: 0;
    gap: 24px;
  }

  .bar,
  .middleBar {
    margin: 0;
  }

  .name {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }

  .team-role {
    font-size: 16px;
    font-weight: 400;
    margin-top: 0px;
    line-height: 18.2px; /* 113.75% */
  }

  // .team-slide {
  //   top: 190px;
  // }
}

// @media (min-width: 1440px) and (max-width: 1727px) {
//   .slider-title {
//     font-size: 96px;
//   }

//   .slick-center .slide-img {
//     width: 328px;
//     height: 410px;
//     filter: none;
//   }

//   .slide-img {
//     width: 220px;
//     height: 220px;
//     margin: auto;
//     transition: filter 0.3s ease;
//     filter: grayscale(100%);
//     object-fit: cover;
//   }

//   .name-role-container {
//     padding-left: 10px;
//     padding-top: 10px;
//   }

//   .bars-animation-container {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     gap: 64px;
//   }

//   .animated-bars {
//     display: flex;
//     align-items: center;
//     margin: 0;
//     gap: 24px;
//   }

//   .bar,
//   .middleBar {
//     margin: 0;
//   }

//   .name {
//     font-size: 24px;
//     font-style: normal;
//     font-weight: 700;
//     line-height: 28px;
//   }

//   .team-role {
//     font-size: 16px;
//     font-weight: 400;
//     margin-top: 0px;
//     line-height: 18.2px; /* 113.75% */
//   }

//   .slider-title {
//     font-size: 64px;
//   }

//   .team-slide {
//     top: 190px;
//   }
// }

@media (min-width: 1280px) and (max-width: 1439px) {
  .slider-title {
    font-size: 96px;
  }

  .slick-center .slide-img {
    width: 328px;
    height: 410px;
    filter: none;
  }

  .slide-img {
    width: 220px;
    height: 220px;
    margin: auto;
    transition: filter 0.3s ease;
    filter: grayscale(100%);
    object-fit: contain;
  }

  .name-role-container {
    padding-left: 30px;
    padding-top: 10px;
  }

  .bars-animation-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 64px;
  }

  .animated-bars {
    display: flex;
    align-items: center;
    margin: 0;
    gap: 24px;
  }

  .bar,
  .middleBar {
    margin: 0;
  }

  .name {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }

  .team-role {
    font-size: 16px;
    font-weight: 400;
    margin-top: 0px;
    line-height: 18.2px; /* 113.75% */
  }

  .slider-title {
    font-size: 64px;
  }

  .team-slide {
    top: 190px;
  }
}

@media (min-width: 834px) and (max-width: 1279px) {
  .slider-title {
    font-size: 64px;
  }

  .slide-img {
    width: 220px;
    height: 220px;
    margin: auto;

    transition: filter 0.3s ease;
    filter: grayscale(100%);
    object-fit: cover;
  }

  .slick-center .slide-img {
    width: 252px;
    height: 316px;
    filter: none;
  }

  .name-role-container {
    padding-left: 15px;
    padding-top: 10px;
  }

  .bars-animation-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 48px;
  }

  .animated-bars {
    display: flex;
    align-items: center;
    margin: 0;
    gap: 16px;
  }

  .name {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }

  .team-role {
    font-size: 16px;
    font-weight: 400;
    margin-top: 0px;
  }

  .slider-title {
    font-size: 64px;
  }

  .team-slide {
    top: 95px;
  }

  .bar,
  .middleBar {
    margin: 0;
  }
}

@media (min-width: 390px) and (max-width: 833px) {
  .slick-center .slide-img {
    width: 240px;
    height: 300px;
    filter: none;
  }

  .slide-img {
    width: 176px;
    height: 176px;
    margin: auto;
    transition:
      transform 0.3s ease,
      filter 0.3s ease;
    filter: grayscale(100%);
    object-fit: cover;
  }

  .name-role-container {
    padding-left: 20%;
    padding-top: 10px;
  }

  .name {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 175% */
  }

  .team-role {
    font-size: 12px;
    font-weight: 400;
    margin-top: 0px;
  }

  .slider-title {
    font-size: 40px;
  }

  .team-slide {
    top: 120px;
  }

  .bars-animation-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 21px;
  }

  .animated-bars {
    display: flex;
    align-items: center;
    margin: 0;
    gap: 16px;
  }
  .bar,
  .middleBar {
    margin: 0;
  }
}

@media (max-width: 389px) {
  .slick-center .slide-img {
    width: 240px;
    height: 300px;
    filter: none;
  }

  .slide-img {
    width: 176px;
    height: 176px;
    margin: auto;
    transition:
      transform 0.3s ease,
      filter 0.3s ease;
    filter: grayscale(100%);
    object-fit: cover;
  }

  .name-role-container {
    padding-left: 5px;
    padding-top: 10px;
  }

  .name {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 175% */
  }

  .team-role {
    font-size: 12px;
    font-weight: 400;
    margin-top: 0px;
  }

  .slider-title {
    font-size: 40px;
  }

  .team-slide {
    top: 120px;
  }

  .bars-animation-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 21px;
  }

  .animated-bars {
    display: flex;
    align-items: center;
    margin: 0;
    gap: 16px;
  }
  .bar,
  .middleBar {
    margin: 0;
  }
}
