.main {
  width: 100%;
  height: 20vh;
  position: relative;

  .mask,
  .body,
  .background-image {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    font-size: 200px;
    font-weight: 900;
    line-height: 66px;
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: white;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none; /* Ensure these elements don't block mouse events */
    span {
      color: #ec4e39;
    }

    
  }

  .body {
    pointer-events: none;
    opacity: 0.15;
  }

  .mask {
    mask-image: url("../../../images/Ellipse9.png");
    mask-repeat: no-repeat;
    -webkit-text-stroke-width: 0;
    -webkit-text-stroke-color: none;
    mask-size: 50px;
    background: black;
    position: absolute;
    z-index: 1;
    mix-blend-mode: screen;
    pointer-events: none;
  }
}

.background-image {
  opacity: 0.99;
  z-index: 3;
  pointer-events: none; /* This also should not intercept events */
}

.fluid-cursor-content {
  pointer-events: auto;
  z-index: 2;
  // opacity: 0;
  transition: opacity 3s ease-in-out; /* Transition applies for both hover in and out */
}

.fluid-cursor-content:hover{
  pointer-events: auto;
  // opacity: 1;

}




