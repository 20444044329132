@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Bungee&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Permanent+Marker&family=Rubik+Bubbles&display=swap");

.Component{
  margin-bottom: 140px;
}
.home-stories {
  margin-top: 6%;
  width: 100%;
  height: 140px;
  color: white;
  text-align: left;
}
.pressableImage {
  width: 176px;
  height: 100%;
  flex-shrink: 0;
  position: inherit;
}
#maliikStory {
  left: 0%;
  top: 0%;
}
#sergioStory {
  left: 13.5%;
  top: 0%;
}
#miguelStory {
  left: 27%;
  top: 0%;
}
#victorStory {
  left: 40.25%;
  top: 0%;
}
#ourStoriesPressable {
  position: relative;
  left: 59.375%;
  top: -130px;
  height: 140px;
  width: 460px;
}
#storiesButton {
  background-color: black;
  border: none;
  color: #fff;
   font-family: Montserrat;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 54px */
  text-transform: uppercase;
}
#storiesButton p {
  left: 0%;
}
#arrowToStories {
  position: relative;
  right: -56.5%;
  bottom: 78px;
}
a button {
  background-color: black;
  border: none;
  position: relative;
}
.storyNames {
  position: absolute;
  display: inline-flex;
  padding: 0.5%;
  justify-content: center;
  align-items: center;
  // gap: 10px;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(2px);
  bottom: 4.28571429%;
  left: 14.0681818%;
  color: #fff;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px; /* 100% */
  text-transform: uppercase;
  width: 124px;
  height: 30px;
}
.successButton{
color: #FFF;
font-family: Montserrat;
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 48px */
text-transform: uppercase;
}

.carouselContainer{
  text-align: center;
  padding-top: 0px;
  background: #000;
  color: #fff;
  position: relative;
  width: 100%;
}

.storyContainer{
  display: grid;
  grid-template-columns: 1fr auto; /* 1fr for text, auto for image */

  background-color: #1A1A1A; /* Adjust background color */
  border-radius: 16px;
  padding: 24px;
  align-items: center;
  
  height: 100%;
  margin: 0 32px;
  gap: 32px;
}

.carouselHeader{
  align-self: stretch;
  text-align: left;
  color: #FFF;
   font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 80% */
  text-transform: uppercase;
  margin: 0 32px 64px;
}
.standardText{
  text-align: left;
  color: #EAEAEA;
   font-family: Montserrat;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  grid-column: 1; /* Text in the first column */
}
.name{
  text-align: left;
  color: #EAEAEA;
   font-family: Montserrat;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  margin-top: 16px;
  grid-column: 1; /* Text in the first column */
}
.highlightedText{
  text-align: left;
  color: #F05A22;
   font-family: Montserrat;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 42px */
  grid-column: 1; /* Text in the first column */
}
.carouselHeaderHighlight{
  color: #F05A22;
   font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  text-transform: uppercase;
}
.profileImage{
  width: 344px;
  height: 344px;
  flex-shrink: 0;
  grid-column: 2; /* Text in the first column */

}
.quotes{
  width: 80px;
  height: 28px;
  grid-column: 1; /* Text in the first column */
  float: left;
}

.textBlock{
  display: flex;
  flex-direction: column; /* Stacks items vertically */
  align-items: flex-start; /* Aligns both image and text to the left */
  align-self: flex-start; /* Aligns to the top of the parent container */
  margin-top: 24px;
}

.read-more-button{
  //Text Style
  color: #FFF;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  //Button Format
  display: flex;
  padding: 4px 4px 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin-top: 8px;
  cursor: pointer;
  //Button Style
  border-radius: 100px;
  border: transparent;
  background: rgba(255, 255, 255, 0.22);
}
.button-icon{
  height: 32px;
  width: 32px;
}

// SLIDER STYLING
// @import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Bungee&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Permanent+Marker&family=Rubik+Bubbles&display=swap");

.slick-prev,
.slick-next {
  display: none !important;
}

.slider-container {
  text-align: center;
  padding-top: 100px;
  background: #000;
  color: #fff;
  position: relative;
  width: 100%;
}

.slider-title {
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 96px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 144px */
  text-transform: uppercase;
}

.slide {
  padding: 5px;
  outline: none;
  transition: transform 0.3s ease, filter 0.3s ease, top 0.3s ease;
  position: relative;
  top: 0px !important; 
}

.center-slide {
  top: 0;
}

.slick-center .slide {
  transform: scale(1.0);
  top: 0; 
}

.slick-center .slide-img {
  width: 250px;
  height: 345px;
  filter: none;
}

.slide-img {
  width: 180px;
  height: 220px;
  margin: auto;
  transition: transform 0.3s ease, filter 0.3s ease;
  filter: grayscale(100%);
  object-fit: cover;
}

.name{
  text-align: center;
  margin-top: 5%;
  margin-bottom: 0;
}

.title {
  text-align: center;
  height: 50px;
  margin: 2%;
}

.animation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.custom-arrow {
  width: 40px;
  height: 40px;
  background: #333;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 10px;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:hover {
    background: #555;
  }

  svg {
    width: 20px;
    height: 20px;
  }
}

.animated-bars {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.bar {
  display: inline-block;
  color: grey !important;
  font-size: 24px;
  margin: 0 5px;
  cursor: pointer;
  // transition: transform 0.3s ease;
  // animation: wave 2s infinite ease-in-out;

  &:hover {
    transform: scale(2.0);
    color: #f05a22 !important; /* Change color when hovered */
  }
}

.bar.active {
  color: white !important; // Color for the active bar
}

@keyframes wave {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.dot {
  color: #fff;
  font-size: 20px;
  margin: 0 5px;
}
.slick-list{
  right: 0.4%;
}



/* Responsive styling for 834px by 1194px */
@media screen and (max-width: 834px) and (orientation: portrait) {
  .storyContainer {
    display: flex;
    flex-direction: column; /* Stack image above text block */
    padding-top: 0px; /* Adjust padding for smaller screen */
    padding-bottom: 0px;
  }

  .highlightedText{
    margin-top: 0px;
  }

  .profileImage{
    width: 240px;
    height: 240px;
  }

  .right {
    order: -1; /* Move image above the textBlock */
    margin-top: 24px;
  }

  .textBlock .quotes {
    display: none; /* Hide quotes image */
  }

  .textBlock {
    align-items: center; /* Center items horizontally */
    justify-content: center;
    text-align: center; /* Center text */
    margin-top: 0px;
  }

  .highlightedText{
    align-items: center; /* Center items horizontally */
    justify-content: center;
    text-align: center; /* Center text */
  }

  .standardText{
    align-items: center; /* Center items horizontally */
    justify-content: center;
    text-align: center; /* Center text */
    margin-top: 0px;
  }

  .read-more-button{
    //Text Style
    color: #FFF;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    //Button Format
    display: flex;
    padding: 4px 4px 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    margin-top: 8px;
    margin-bottom: 24px;
    cursor: pointer;
    //Button Style
    border-radius: 100px;
    border: transparent;
    background: rgba(255, 255, 255, 0.22);
  }

  .carouselHeader {
    align-self: stretch;
    text-align: left;
    color: #FFF;
     font-family: Montserrat;
    text-transform: uppercase;
    margin: 0 32px 64px;
  
    /* Scaling font size based on viewport width */
    // font-size: clamp(24px, 5vw, 40px); /* Minimum 24px, scales up to 40px */
    font-size: 32px;
    line-height: 1.2;
  }

  .carouselHeaderHighlight {
    color: #F05A22;
     font-family: Montserrat;
    text-transform: uppercase;
  
    /* Scaling font size based on viewport width */
    // font-size: clamp(24px, 5vw, 40px); /* Minimum 24px, scales up to 40px */

    font-size: 32px;
    font-weight: 500;
    line-height: 1.2;
  }  
  
}


/* Responsive styling for 390px by 844px */
@media screen and (max-width: 390px) and (orientation: portrait) {
  .storyContainer {
    display: flex;
    flex-direction: column; /* Stack image above text block */
    padding: 0px 12px 0px 12px;
    border-radius: 8px;
    margin-left: 16px;
    margin-right: 16px;
    margin: 0px 16px 0px 16px;
    gap: 16px;
  }

  // .animation-container{
  //   margin-top: 0px;
  // }

  .profileImage{
    width: 140px;
    height: 140px;
  }

  .right {
    order: -1; /* Move image above the textBlock */
    margin-top: 24px;
  }

  .textBlock .quotes {
    display: none; /* Hide quotes image */
  }

  .textBlock {
    align-items: center; /* Center items horizontally */
    justify-content: center;
    text-align: center; /* Center text */
    margin-top: 0px;
  }

  .highlightedText{
    align-items: center; /* Center items horizontally */
    justify-content: center;
    text-align: center; /* Center text */
    font-size: 16px;
  }

  .standardText{
    align-items: center; /* Center items horizontally */
    justify-content: center;
    text-align: center; /* Center text */
    margin-top: 0px;
    font-size: 16px;
  }

  .read-more-button{
    //Text Style
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    //Button Format
    display: flex;
    padding: 4px 4px 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    margin-top: 8px;
    margin-bottom: 24px;
    cursor: pointer;
    //Button Style
    border-radius: 100px;
    border: transparent;
    background: rgba(255, 255, 255, 0.22);
  }

  .carouselHeader {
    display: none; /* Hide quotes image */
  }

  .carouselHeaderHighlight {
    display: none; /* Hide quotes image */
  }  
  
}