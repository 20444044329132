@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Bungee&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Permanent+Marker&family=Rubik+Bubbles&display=swap");


@font-face {
  font-family: "Gotham";
  src: url(../../../public/GothamBold.otf) format("opentype");
}

/* Keyframes for fade and translate animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.servicesTopContainer {
  width: inherit; /* Adjust as needed */
  // hardcoding this height for now, will try to figure out how to make it automatic
  height: 380px; /* Adjust as needed */
  margin-bottom: 45px;
}

.videoContainer {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.serviceVideo {
  width: 100%;
  height: fit-content;
  display: block; /* Remove any default inline styling */
  position: absolute; /* Position absolutely within the container */

  left: 48%; /* Center horizontally */
  transform: translate(-48%, -52%); /* Centering trick */
  filter: brightness(60%); /* Adjust the percentage to control the darkness */
}

.educationVideo {
  filter: brightness(40%); /* Adjust the percentage to control the darkness */
}

.cardTitleContainer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 1;
}

.cardTitle {
  color: #FFF;
   font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 120% */
  text-transform: uppercase;
  margin: 20px 0 0 35px;
  text-align: left;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: #000;
}

.cardDescription {
  margin-left: 40px;
  max-width: 805px;
  color: rgba(255, 255, 255, 0.90);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 33.6px */
  text-align: left;
}

.softwareCardBottom, .educationCardBottom {
  border-radius: 0px 0px 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: hidden;
}

.softwareCardBottom {
  margin-bottom: 64px;
}

// BREAKPOINTS
@media screen and (max-width: 1279px) {
  .servicesTopContainer {
    height: 220px;
    margin-bottom: 0px;
  }

  .cardTitle {
    font-size: 28px;
    margin-left: 24px;
  }

  .cardDescription {
    font-size: 18px;
    margin-left: 24px;
  }

  .softwareCardBottom {
    margin-bottom: 48px;
  }
}

@media screen and (max-width: 833px) {
  .servicesTopContainer {
    height: 220px;
  }

  .cardTitle {
    font-size: 28px;
    line-height: 120%; /* 33.6px */
    margin: 10px 0 0 16px;
    font-weight: 700;
  }

  .cardDescription {
    font-size: 15px;
    margin-left: 16px;
    max-width: 549px;
  }

  .softwareCardBottom {
    margin-bottom: 28px;
  }
}


@media screen and (min-width: 1728px) {
  .servicesTopContainer {
    height: 420px; /* Adjust as needed */
  }

  .cardTitle {
    font-size: 48px;
    margin-left: 100px;
  }

  .cardDescription {
    font-size: 32px;
    max-width: 1076px;
    margin-left: 100px;
  }

  .softwareCardBottom, .educationCardBottom {
    margin-bottom: 100px;
  }
}