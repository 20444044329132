// @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Koulen&display=swap");

// .navbar {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 16px 48px;
//   position: fixed;
//   top: 0;
//   width: 100%;
//   z-index: 10;
//   font-family: Inter;

//   &.hidden {
//     top: -80px;
//   }

//   &.transparent {
//     background-color: transparent;
//     backdrop-filter: blur(4px);
//     top: 0;
//   }

//   .navbar-brand {
//     a {
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       gap: 12px;

//       #txt-labs-logo {
//         width: 34px;
//         height: 40px;
//       }

//       #txt-labs-title {
//         width: 139px;
//         height: 20px;
//       }
//     }
//   }

//   .navbar-links {
//     display: flex;
//     align-items: center;
//     gap: 32px;

//     a {
//       color: white;
//       text-decoration: none;
//     }

//     .nav-arrow {
//       width: 48px;
//       height: 48px;
//     }

//     .navbar-dropdown {
//       position: relative;

//       .dropdown-menu {
//         position: absolute;
//         top: 100%;
//         left: 0;
//         background-color: #444;
//         box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
//         z-index: 1;

//         a {
//           display: block;
//           padding: 0.5rem 1rem;

//           &:hover {
//             background-color: #555;
//           }
//         }
//       }
//     }
//   }
// }

@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Bungee&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Permanent+Marker&family=Rubik+Bubbles&display=swap");


.navbar-contact-btn {
  display: flex;
  padding: 2px 2px 2px 16px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  background: rgba(255, 255, 255, 0.22);
  font-weight: 500;
  font-size: 16px;
  border-radius: 100px;
  line-height: normal;
  border: none;
  color: white;
  transition: opacity 0.5s ease-in-out; /* Add transition for opacity */

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

#bottom-side-bar {
  display: none;
}

#top-side-bar {
  z-index: 1000;
  display: inline-flex;
  width: calc(100% - 50px);
  padding: 16px 24px;
  justify-content: space-between;
  // align-items: center;
  padding-top: 24px;
  background: rgba(0, 0, 0, 0.01);
  backdrop-filter: blur(1.5px);

  position: fixed; /* Fix the navbar to the top of the screen */
  top: 0; /* Align it to the top */
  left: 0; /* Align it to the left */

  &.hidden {
    display: none;
  }

  &.transparent {
    background-color: transparent;
    // backdrop-filter: blur(4px);
    display: inline-flex;
  }
}

#center-links {
  display: flex;
  align-items: center;
  gap: 48px;
  align-self: stretch;
  margin: auto;
}

.menu-icon {
  display: none;
}

#contact-button-navbar {
  display: inline-flex;
}

#contact-button-navbar:hover {
  cursor: pointer;
}

.navbar-link {
  color: #fff;
   font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  text-decoration: none; /* Remove underline */

  &.active {
    color: #f05a22;
  }
  transition: color 0.3s ease; /* Smooth transition for color change */

  &:hover {
    color: rgb(131, 131, 131);
  }
}

@media (min-width: 834px) and (max-width: 1279px) {
  .no-scroll {
    overflow: hidden;
  }

  #top-side-bar {
    display: flex;
    // width: 390px;
    padding: 16px 24px;
    justify-content: space-between;
    align-items: center;
    padding-top: 24px;
  }

  #bottom-side-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: rgba(6, 6, 6, 0.8);
    backdrop-filter: blur(20px);
    height: 100%;
    z-index: 100;
    overflow: hidden;
  }

  #contact-button-navbar {
    display: none;
  }

  #contact-button-navbar:hover {
    cursor: pointer;
  }

  #center-links {
    display: none;
  }

  .navbar-brand {
    flex-shrink: 0;
    z-index: 1001;
  }

  .menu-icon {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }

  .menu-icon.hamburger {
    transform: rotate(0deg);
  }

  .menu-icon.close {
    transform: rotate(90deg);
  }

  #hamburger-icon {
    // display: flex;
    // position: absolute;
    z-index: 1001;
    flex-shrink: 0;
  }

  #links-column-container {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 64px;
  }

  #links-column-separator {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 36px;
    align-self: stretch;
  }

  .mobile-navbar-link {
    color: #fff;
    text-align: center;
     font-family: Montserrat;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 42px */
    text-transform: uppercase;
    text-decoration: none; /* Remove underline */

    &.active {
      font-weight: 500;
    }
  }

  #txt-labs-title {
    transition: opacity 0.5s ease-in-out; /* Add transition for opacity */

    &.active {
      opacity: 0.6;
    }
  }

  .contact-button {
    max-width: 210px;
    height: auto;
  }
}

@media (max-width: 833px) {
  .no-scroll {
    overflow: hidden;
  }

  #top-side-bar {
    display: flex;
    // width: 390px;
    padding: 12px 16px;
    justify-content: space-between;
    align-items: center;
    padding-top: 24px;
  }

  #bottom-side-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: rgba(6, 6, 6, 0.8);
    backdrop-filter: blur(20px);
    height: 100%;
    z-index: 100;
    overflow: hidden;
  }

  #contact-button-navbar {
    display: none;
  }

  #center-links {
    display: none;
  }

  .navbar-brand {
    flex-shrink: 0;
    z-index: 1001;
  }

  .menu-icon {
    transition: transform 0.5s ease-in-out;
    display: flex;
  }

  .menu-icon.hamburger {
    transform: rotate(0deg);
  }

  .menu-icon.close {
    transform: rotate(90deg);
  }

  #hamburger-icon {
    display: flex;
    // position: absolute;
    z-index: 1001;
    flex-shrink: 0;
  }

  #links-column-container {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 64px;
  }

  #links-column-separator {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 36px;
    align-self: stretch;
  }

  .mobile-navbar-link {
    color: #fff;
    text-align: center;
     font-family: Montserrat;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 42px */
    text-transform: uppercase;
    text-decoration: none; /* Remove underline */

    &.active {
      font-weight: 500;
    }
  }

  #txt-labs-title {
    transition: opacity 0.5s ease-in-out; /* Add transition for opacity */

    &.active {
      opacity: 0.6;
    }
  }
}
