.text-logo-container-card {
    // margin: 0 48px 48px 24px;
    padding: 0;
    box-sizing: border-box;
    height: 30px;
 
 
 }
 
 
 // modify x axis from starting to position to allow to move locations
 @keyframes slidecard {
    from {
        /* shift element on x axis */
        transform: translateX(0);
 
 
    }

    to {
        transform: translateX(-100%);
    }
 }
 
 
 .text-logos-card {
    overflow: hidden;
    padding: 0;
    background: black;
    white-space: nowrap;
    position: relative;
 
 
 }
 
 
 // this section is to add the overall gradient to the page
 .text-logos-card::before,
 .text-logos-card::after {
    position: absolute;
    top: 0;
    width: 50px;
    height: 100%;
    content: "";
    z-index: 2;
 }
 
 
 // specific gradient  for teh left slide
 .text-logos-card::before {
    left: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), black);
 }
 
 
 // specific gradient for the right side
 .text-logos-card::after {
    right: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), black);
 }
 
 
 
 
 .text-logos-slide-card {
    display: inline-flex;
    animation: 30s slidecard infinite linear;
 
 
 }
 
 
 .text-logo-content-card {
    display: inline-flex;
    margin: 0 8px;
    color: white;
    font-size: 20px;
 
 
    &-plus {
        margin-right: 8px;
    }
 }
 
 