@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Bungee&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Permanent+Marker&family=Rubik+Bubbles&display=swap");

.impact-text-container {
  width: auto;
  height: auto;
  position: relative;
  margin:0;
}

.number-container {
  position: relative;
  top: 0;
  left: 0;
  transform: none;
}

// .number-scroll {
//   display: flex;
//   padding: 0px 100px;
//   justify-content: space-between;
//   align-items: center;
//   flex-shrink: 0;

//   &-item {
//     display: inline-flex;
//     justify-content: center;
//     align-items: center;
//     color: #fff;
//     text-align: center;
//     font-family: Montserrat;
//     font-size: 96px;
//     font-style: normal;
//     font-weight: 700;
//     line-height: 150%; /* 144px */
//     text-transform: uppercase;
//   }
// }
.number-scroll {
  width: auto;
  height: auto;
  display: inline-flex;
  padding: 24px 16px 80px 16px;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: flex-start;
  gap: 48px;

  &-item {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 76.8px */
    text-transform: uppercase;
    align-self: stretch;
  }
}

.number-text-module {
  color: white;
  font-size: 64px;
}

.odometer {
  font-size: 64px;
}

.number-text-desc {
  width: auto;
  color: #fff;
  text-align: center;
   font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 48px */
}

.impact-color-text-container {
  position: relative;
}

.impact-text-color-text{
  width: 0;
  height: 0;
  overflow: hidden;
}

@media screen and (min-width: 390px) {
  .impact-text-container {
    margin:  0;
  }

  .number-container {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
  }

  .number-scroll {
    width: auto;
    height: auto;
    display: inline-flex;
    padding: 24px 16px 80px 16px;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-start;
    gap: 48px;

    &-item {
      color: #fff;
      text-align: center;
      font-size: 64px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 76.8px */
      text-transform: uppercase;
      align-self: stretch;
    }
  }
  .number-text-module {
    color: white;
    font-size: 64px;
  }
  .odometer {
    font-size: 64px;
  }
  .number-text-desc {
    color: #fff;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 36px */
    width: 358px;
  }
  .impact-text-color-text{
    width: 0;
    height: 0;
    overflow: hidden;
  }
}



@media screen and (min-width: 834px) {

  .impact-text-container {
    margin: 74px 0;
  }
  

  .number-container {
    position: absolute;
    z-index: 5;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }
  
  .number-scroll {
    display: flex;
    padding: 0px 24px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;

    &-item {
      font-size: 48px;
    }
  }
  .number-text-module {
    color: white;
    font-size: 48px;
  }
  .odometer {
    font-size: 48px;
  }
  .number-text-desc {
    width: auto;

    font-size: 15px;
  }
  .impact-text-color-text{
    width: auto;
    height: auto;
    overflow: visible;
  }
}

@media screen and (min-width: 1280px) {
  .impact-text-container {
    margin: 150px 0;
  }
  .number-scroll {
    display: flex;
    padding: 0px 100px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;

    &-item {
      font-size: 64px;
    }
  }
  .number-text-module {
    color: white;
    font-size: 64px;
  }
  .odometer {
    font-size: 64px;
  }
  .number-text-desc {
    font-size: 24px;
  }
}

@media screen and (min-width: 1728px) {
  .number-scroll {
    display: flex;
    padding: 0px 100px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;

    &-item {
      font-size: 96px;
      font-weight: 700;
      line-height: 150%; /* 144px */
    }
  }
  .number-text-module {
    color: white;
    font-size: 96px;
  }
  .number-text-desc {
    font-size: 32px;
  }
  .odometer {
    font-size: 96px;
  }

}
